import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description }: { title: string; description: string }) => {
  return (
    <Helmet>
      <meta
        property='og:image'
        content={window.settings.REACT_RESOURCES_BASE_URL + '/Resources/images/header-logo.svg'}
      />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />

      <meta
        property='twitter:image'
        content={window.settings.REACT_RESOURCES_BASE_URL + '/Resources/images/header-logo.svg'}
      />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={window.location.href} />

      <meta name='title' content={title} />
      <meta name='description' content={description} />
      <meta name='keywords' content={title} />
    </Helmet>
  );
};

export default MetaTags;
