import { useTranslation } from 'react-i18next';
import { ButtonLoader } from './ButtonLoader';
import Img from './Img';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAuth } from 'react-oidc-context';

export default function SessionExpirePopup() {
  const { t } = useTranslation(['translation']);
  const [domReady, setDomReady] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    setDomReady(true);
  }, []);

  const handleConfirm = () => {
    auth.signoutSilent().then(() => {
      window.parent.location.href = '/';
      localStorage.clear();
      localStorage.removeItem('token');
    });
  };
  const element = (
    <>
      <div
        className='modal custom-popup print-receipt-popup'
        id='Sesssion-expire-popup'
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <div className='relative modal-dialog w-full md:w-1/3'>
          <div className='modal-content'>
            <div className='modal-body text-center	'>
              <div className='modal-head border-0 bottom-0'>
                <Img
                  className='w-[50px]'
                  data-testid={'ICImg'}
                  src={'/Resources/images/error.svg'}
                  alt=''
                  title=''
                />
              </div>
              <div className='flex flex-col p-3 breakLine'>
                <p>{t('translation:session_expired')}</p>
                <ButtonLoader
                  data-testid='errorBTN'
                  className={'ta btn btn-secondary btn-sm mt-5'}
                  type='button'
                  isLoading={false}
                  onClick={handleConfirm}
                >
                  {t('translation:confirm')}
                </ButtonLoader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return domReady ? ReactDOM.createPortal(element, document.getElementById('portal-modal')!) : null;
}
