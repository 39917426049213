export const MBI_ENDPOINTS = {
  PURPOSE_OF_USE_LOOKUP: '/V1/Lookups/VehiclePurpose',
  EFFECTIVE_DATE: '/V1/Application/Configuration',
  CREATE_APPLICATION: '/V1/Applications',
  GET_APPLICATION: (appRef: string) => `/V1/Application/${appRef}`,
  POLICYHOLDER_AND_VEHICLE: (appRef: string) => `/V1/Application/${appRef}/Header`,
  GET_APP: '/V1/Application/',
  PAYMENT_HEADER: '/PaymentHeader',
  SEND_OTP: '/V1/SendOTP',
  VERIFY_OTP: '/V1/VerifyOTP',
  PAY: '/V1/Pay',
  GET_CAPTCHA: '/V1/Captcha',
  SELECT_QUOTE: '/V1/SelectQuote',
  GET_APPLICATION_SUMMARY: '/GetApplicationSummary',
  GET_APPLICATION_POLICY_DPWNLOAD: (appRef: string) => `/V1/Applications/${appRef}/Download`,
  GET_REQUEST_DATA: (appRef: string) => `/V1/Application/${appRef}/RequestData`,
  GET_LOOKUPS_MISSING_DATA: '/V1/Lookups/GetLookupsMissingData',
  GET_SEQUENCES_IDS: '/V1/Application/GetSuggestedSequences',
};
