import { configureStore } from '@reduxjs/toolkit';
import mbiSlice from './slices/mbi.slice';
import purchaseFlowSlice from './slices/purchaseFlow.slice';
import offersSlice from './slices/offer.slice';
import paymentFlowSlice from './slices/paymentFlow.slice';
import addonsSlice from '@Components/purchaseFlow/Addons/app/addons.slice';

export const store = configureStore({
  reducer: {
    purchase: purchaseFlowSlice,
    mbi: mbiSlice,
    offers: offersSlice,
    payment: paymentFlowSlice,
    addons: addonsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
