import http from '../http';
import { ENDPOINTS } from './endpoints';
import { TMotorCreateApplicationData } from '@models/motorCreateApplication.model';
import { IInsuranceFormData } from '@models/insuranceData.model';
import { InsuranceTypes } from '@models/enums/insuranceType.enum';
import { removeComma } from 'src/utils/commonUtils';
import { THomeBanner } from '@models/home.model';

function addDays(date: Date | string, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const service = {
  async createApplication(
    form: TMotorCreateApplicationData,
    type: number,
    registrationType: number,
  ) {
    const body: any = {
      identityNumber: form.id,
      isOwnerShipTransfer: type === InsuranceTypes.OwnershipTransfer ? true : false,
    };

    if (registrationType === InsuranceTypes.CustomCard) body.customNumber = form.customNumber;
    else body.sequenceNumber = form.documentNumber;
    if (
      type === InsuranceTypes.OwnershipTransfer ||
      registrationType === InsuranceTypes.CustomCard
    ) {
      body.dateOfBirthMonth = 1;
      body.dateOfBirthYear = 2000;
      if (!form.id.toString().startsWith('7')) {
        body.dateOfBirthMonth = form.birthMonth?.value;
        body.dateOfBirthYear = form.birthYear?.value;
      }
    }
    if (registrationType === InsuranceTypes.CustomCard) {
      body.manufactureYear = form.yearOfManufactureCustome?.value;
    } else if (type === InsuranceTypes.OwnershipTransfer) {
      body.manufactureYear = form.yearOfManufactureSeq?.value;
    }

    return await http.post(ENDPOINTS.CREATE_APPLICATION, body).then((res) => {
      if (res?.success) {
        sessionStorage.setItem('applicationData', JSON.stringify(res.applicationData));
        if (res.applicationData?.model?.token)
          localStorage.setItem('token', res.applicationData?.model?.token);
        // localStorage.setItem(
        //   'token',
        //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI1MDg4MSIsIlRpbWVTdGFtcCI6IjE3MDkwMjE5NjU3NzMiLCJOYW1lIjoi2KfZhdis2K8g2LnYt9inINi52KjYryDYp9mE2LHYrdmF2YYg2YrYrdmK2YkiLCJFbWFpbCI6ImFtamRAZ21haWwuY29tIiwiTG9naW5UaW1lIjoiMi8yNy8yMDI0IDExOjE5OjI2IEFNIiwiSXNEdW1teUFjY291bnQiOiJGYWxzZSIsIklkZW50aXR5TnVtYmVyIjoiMjM1MzY1OTQzMiIsIm5iZiI6MTcwOTAyMTk2NiwiZXhwIjoxNzA5MjgxMTY1LCJpYXQiOjE3MDkwMjE5NjYsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzE3IiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMTcifQ.qDiUJxJl_AsDuVBUlkEFSpEs7OPjLRn1Zn4HExeKSB0',
        // );
        if (res.applicationData?.model?.missingData?.address?.invalid)
          return { invalidAddress: true };
        return res.applicationData;
      }
    });
  },
  async updateApplication(form: IInsuranceFormData, appRef: string, isCompany: boolean) {
    const { healthconditions, trafficViolations, licenses } = form;
    const healthconditionsValue: (string | number)[] | undefined = healthconditions?.map((obj) => {
      return obj.value;
    });
    const trafficViolationsValue: (string | number)[] | undefined = trafficViolations?.map(
      (obj) => {
        return obj.value;
      },
    );
    const licensesValue: { id: string; numberOfYears: string }[] | undefined = licenses?.map(
      (obj) => {
        return {
          id: obj.country.value,
          numberOfYears: obj.years.value,
        };
      },
    );

    const model = {
      shouldShowTameeniRecommendation: form.shouldShowTameeniRecommendation,
      shouldApplyScheme: form.shouldApplyScheme,
      effectiveDate: addDays(form.effectiveDate, 1).toISOString().substring(0, 10),
      missingData: {
        address: {
          cityId: form.city?.value ?? null,
          city: form.city?.label ?? null,
          district: (form.district as string)?.trim() ?? null,
        },
        vehicle: {
          makeId: form.make?.value ?? null,
          modelId: form.model?.value ?? null,
          capacity: (form?.capacity && parseInt(form?.capacity)) ?? null,
          manufactureYear: form.manufactureYear?.value ?? null,
        },
        person: isCompany
          ? null
          : {
              selectedMartialStatus: form.martialStatus === 'single' ? 1 : 2,
              selectedLicenseId: form.licenseType?.value,
              selectedLicenseNumberOfYears: form.licenseYears?.value,
              selectedLicenseName: form.licenseType?.label,
            },
      },
      policyHolderVehicle: {
        vehicle: {
          insuranceType: form?.insuranceType && parseInt(form?.insuranceType),
          estimatedValue: removeComma(form?.estimatedValue),
          repairMethod: (form?.repairMethod && parseInt(form?.repairMethod)) ?? 2,
          purposeId: form.purposeOfUse.value,
          nightParkingAreaId: form.vehicleNightParking?.value,
          expectedKMPerYearId: form.expectedKM?.value,
          transmissionTypeId: form.transmissionType?.value,
          customization: form.modification,
          trailEstimatedValue: removeComma(form?.trailEstimatedValue),
        },
        person: isCompany
          ? null
          : {
              numberOfAccidents: form.accidentCounts?.value,
              entityNameLocation: form.workNameAndLocation,
              childrenBelow16Years: form.children?.value,
              educationId: form.education?.value,
              healthConditionRestrictions: healthconditionsValue,
              trafficViolations: trafficViolationsValue,
              internationalLicenses: licensesValue,
            },
      },
    };

    return await http.put(ENDPOINTS.UPDATE_APPLICATION, model, { ApplicationReference: appRef });
  },
  async getVehicleDetails(appRef: string) {
    return await http.get(ENDPOINTS.VEHICLE_DETAILS, {
      ApplicationReference: appRef,
    });
  },
  async getApplication(appRef: string) {
    return await http.get(ENDPOINTS.GET_APPLICATION, {
      ApplicationReference: appRef,
    });
  },
  async getFirstStep(appRef: string) {
    return await http.get(ENDPOINTS.GET_FIRST_STEP, {
      ApplicationReference: appRef,
    });
  },
  async getHomeAnnouncement() {
    return await http.get(ENDPOINTS.HOME_ANNOUNCEMENT);
  },
  async getHomeBenner(): Promise<THomeBanner[]> {
    return await http.get(ENDPOINTS.HOME_BANNER);
  },
  async getSequencesIds(id: string) {
    const authToken =
      JSON.parse(
        localStorage.getItem(
          `oidc.user:${window.settings.VITE_AUTH_AUTHORITY}:${window.settings.VITE_AUTH_CLIENT_ID}`,
        ) as string,
      )?.access_token ?? null;

    if (authToken || localStorage.getItem('token')) {
      return await http.get(ENDPOINTS.GET_SEQUENCES_IDS, {
        policyHolderId: id,
      });
    } else {
      return { suggestedSequences: [] };
    }
  },
  async getLookupsExpirationDate() {
    return await http.get(ENDPOINTS.GET_LOOKUPS_EXPIRATION_DATE);
  },

  async updateCreatedBy() {
    const headers: any = {};
    headers.token = localStorage.getItem('token') ?? null;
    return await http.put(ENDPOINTS.UPDATE_CREATE_BY, {}, {}, headers);
  },
};

export default service;
