import { dataLayerPush } from '@Services/ga4';
import { GA4Events } from '@Services/ga4Events';
import { useEffect, useRef, useState } from 'react';

export default function useHeaderSetting() {
  const [show, setShow] = useState<boolean>(false);
  const humburgerMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (show) {
      dataLayerPush(GA4Events.sidebarOpen.event, []);
    }
  }, [show]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (humburgerMenuRef.current) {
      humburgerMenuRef.current.classList.toggle('is-active');
      setShow((prev) => !prev);
    }
  };

  useEffect(() => {
    function handleClickOutSide() {
      if (humburgerMenuRef.current) {
        humburgerMenuRef.current.classList.remove('is-active');
        setShow(false);
      }
    }

    document.addEventListener('click', handleClickOutSide);

    return () => document.removeEventListener('click', handleClickOutSide);
  });
  return { show, humburgerMenuRef, handleClick };
}
