import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useModal() {
  const [showModal, setShowModal] = useState(false);
  const [modalTarget, setModalTarget] = useState<string | null>(null);
  const [params] = useSearchParams();

  function handleShowModal(this: any, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    setModalTarget(null);
    const portalEle = document.getElementById('portal-modal');
    const isDrawer = e.currentTarget.dataset.type as string;
    if (isDrawer === 'drawer') {
      document.body.style.overflowY = 'auto';
      portalEle?.classList.add('drawer');
    } else {
      document.body.style.overflowY = 'hidden';
      portalEle?.classList.remove('drawer');
    }

    setShowModal(true);
    if (portalEle) {
      portalEle.classList.add('show');
      const targetModalId = e.currentTarget.dataset.targetModal as string;
      setTimeout(() => {
        if (targetModalId) {
          setModalTarget(targetModalId);
          setTimeout(() => {
            const targetModalDiv = document.getElementById(targetModalId.replace('#', ''));

            if (targetModalDiv) targetModalDiv.classList.add('show');
            const modalDialogEle = document.querySelectorAll('.modal-dialog');
            if (modalDialogEle) {
              [...modalDialogEle]?.map((element: Element) => {
                element.classList.add('modal-dialog-show');
              });
            }
          }, 500);
        }
      }, 200);
    }
  }

  const handleCloseModal = () => {
    const compareArray = JSON.parse(sessionStorage.getItem('quotesCompareList') || '[]');
    if (Number(params.get('step')) === 3 && compareArray?.length > 0) document.getElementById('Compare-popup-trigger')?.click();
    else {
      document.body.style.overflowY = 'auto';
      const portalEle = document.getElementById('portal-modal');
      if (portalEle) {
        portalEle.classList.remove('show');
        const modalDialogEle = document.querySelectorAll('.modal-dialog');
        const allModals = document.querySelectorAll('.modal');
        if (allModals) {
          [...allModals]?.map((element: Element) => {
            element?.classList.remove('show');
          });
        }
        if (modalDialogEle) {
          [...modalDialogEle]?.map((element: Element) => {
            element?.classList.remove('modal-dialog-show');
          });
        }
        setTimeout(() => {
          setModalTarget(null);
          setShowModal(false);
        }, 10);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', (e: MouseEvent) => {
      if ((e.target as HTMLElement).classList.contains('modal')) {
        const compareArray = JSON.parse(sessionStorage.getItem('quotesCompareList') || '[]');
        if (Number(params.get('step')) === 3 && compareArray?.length > 0)
          document.getElementById('Compare-popup-trigger')?.click();
        else handleCloseModal();
      }
    });

    return () => document.removeEventListener('click', handleCloseModal);
  });

  return { showModal, modalTarget, handleShowModal, handleCloseModal };
}
