declare const window: Window & { dataLayer: any };
import { IQuote } from '@models/quoteList.model';
import { GA4Events, getCurrentTabName } from './ga4Events';
import http from './http';
import { QuoteTypes } from '@models/enums/quoteList.enum';
import { FLOWS } from '@models/enums/ga4.enum';
import { hash } from 'src/utils/commonUtils';

export const dataLayerPush = (event: string, params: { key: string; value: string | number }[]) => {
  window.dataLayer = window.dataLayer || [];
  const objectToPush: any = {
    event: event?.toLowerCase(),
    isHybridApp: navigator.userAgent.includes('HybridApp'),
  };

  const callbackBody: any = {};
  params?.forEach((param) => {
    if (param.value) {
      objectToPush[param.key] = param.value.toString().toLowerCase();
      callbackBody[param.key] = undefined;
    }
  });

  window.dataLayer.push({
    ...objectToPush,
    eventCallback: function () {
      window.dataLayer.push(callbackBody);
    },
  });
};

export const eCommmercePush = (
  event: string,
  params: { key: string; value: string | number | any[] }[],
) => {
  window.dataLayer = window.dataLayer || [];
  const ecommerce: any = {};

  window.dataLayer.push({ ecommerce: null });
  params?.forEach((param) => {
    ecommerce[param.key] =
      typeof param.value === 'string' ? param.value?.toLowerCase() : param.value;
  });

  const eventsToAddHashedUserData = [
    GA4Events.beginCheckout.event,
    GA4Events.addPaymentInfo.event,
    GA4Events.purchase.event,
  ];
  if (eventsToAddHashedUserData.includes(event)) {
    try {
      const profileObject =
        JSON.parse(
          localStorage.getItem(
            `oidc.user:${window.settings.VITE_AUTH_AUTHORITY}:${window.settings.VITE_AUTH_CLIENT_ID}`,
          ) as string,
        )?.profile ?? null;
      hash(profileObject?.email).then((res) => (ecommerce.e = res));
      hash(profileObject?.phone_number).then((res) => (ecommerce.p = res));
    } catch (error) {
      ecommerce.e = undefined;
      ecommerce.p = undefined;
    }
  }

  window.dataLayer.push({
    event: event.toLowerCase(),
    ecommerce,
    isHybridApp: navigator.userAgent.includes('HybridApp'),
  });
};

export const getEnglishFromArabicTranslation: (
  translation: string,
  ns?: string,
) => Promise<string | null> = async (translation, ns: string = 'translation') => {
  const arTranslations = await http.getCorsRequest(
    `${window.settings.VITE_RESOURCES_BASE_URL}/locales/ar/${ns}.json?v=${window.settings.VITE_VERSION}`,
  );
  for (const key in arTranslations) {
    if (arTranslations[key] === translation) {
      return await getEnglishFromKey(key, ns);
    }
  }
  return null;
};

export const getEnglishFromKey = async (key: string, ns: string = 'translation') => {
  const enTranslations = await http.getCorsRequest(
    `${window.settings.VITE_RESOURCES_BASE_URL}/locales/en/${ns}.json?v=${window.settings.VITE_VERSION}`,
  );
  return enTranslations[key] ?? 'something went wrong';
};

export const quoteToEecItemObject = (quote: IQuote, index: number, quoteType: QuoteTypes) => {
  let discountsVal = 0;
  if (quoteType === QuoteTypes.TPL)
    quote?.discounts?.map((discount) => (discountsVal += discount?.discountAmount));
  else {
    quote?.deductible?.map((deductible) => {
      if (deductible?.deductibleAmount === quote.userSelection?.deductibleAmount) {
        deductible?.discounts?.map((discount) => (discountsVal += discount?.discountAmount));
      }
    });
  }
  const affiliation = sessionStorage.getItem('affiliation') ?? undefined;
  sessionStorage.removeItem('affiliation');
  return {
    item_name: quote?.quoteTitleEnglish,
    item_id: quote?.responseCorrelationId,
    item_brand: quote?.insuranceCompanyNameEN,
    item_category: getCurrentTabName(quoteType),
    currency: 'sar',
    discount: discountsVal.toString(),
    index: index?.toString(),
    item_list_id: Number(quoteType).toString(),
    item_list_name: getCurrentTabName(quoteType),
    price: quote?.userSelection.totalOfferAmount.toFixed(2).toString(),
    flow_name: FLOWS.MOTOR,
    affiliation,
  };
};
