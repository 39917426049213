import mbiHttp from './mbiHttp';
import { MBI_ENDPOINTS } from './endpionts';
import { TMBICreateApp, TPay, TSelectQuote, TMissingData } from '@models/mbi.model';

const authToken = JSON.parse(
  localStorage.getItem(`oidc.user:${window.settings.VITE_AUTH_AUTHORITY}:${window.settings.VITE_AUTH_CLIENT_ID}`) as string,
)?.access_token ?? null;
const services = {
  // Purposes of use
  async getPurposesOfUse() {
    return await mbiHttp.get(MBI_ENDPOINTS.PURPOSE_OF_USE_LOOKUP);
  },
  // Effective Date
  async getEffectiveDate() {
    return await mbiHttp.get(MBI_ENDPOINTS.EFFECTIVE_DATE);
  },
  //Get Captcha
  async getCaptcha() {
    return await mbiHttp.get(MBI_ENDPOINTS.GET_CAPTCHA);
  },
  // Create MBI Application
  async createMBIApp(data: TMBICreateApp) {
    const body = {
      ...data,
    };
    return await mbiHttp.post(MBI_ENDPOINTS.CREATE_APPLICATION, body);
  },
  // GET REQUEST DATA
  async getRequestData(appRef: string) {
    return await mbiHttp.get(MBI_ENDPOINTS.GET_REQUEST_DATA(appRef));
  },
  // GET MBI Application
  async getMBIApp(appRef: string) {
    return await mbiHttp.get(MBI_ENDPOINTS.GET_APPLICATION(appRef));
  },
  //payment header
  async getPaymentHeader(param: string) {
    return await mbiHttp.get(MBI_ENDPOINTS.GET_APP + param + MBI_ENDPOINTS.PAYMENT_HEADER);
  },
  //send otp
  async sendOTP(param: string, mobile: string, isResend: boolean) {
    const body = {
      applicationReference: param,
      mobile: mobile,
      isResend: isResend,
    };
    return await mbiHttp.post(MBI_ENDPOINTS.SEND_OTP, body);
  },
  //verify otp
  async verifyOTP(param: string, otp: number, mobileNumber: string) {
    const body = {
      applicationReference: param,
      otp: otp,
      mobileNumber: mobileNumber,
    };
    return await mbiHttp.post(MBI_ENDPOINTS.VERIFY_OTP, body);
  },
  //verify otp
  async pay(data: TPay) {
    const body = {
      ...data,
    };
    return await mbiHttp.post(MBI_ENDPOINTS.PAY, body);
  },
  async getPolicyHolderAndVehicle(appRef: string) {
    return await mbiHttp.get(MBI_ENDPOINTS.POLICYHOLDER_AND_VEHICLE(appRef));
  },
  // Selected Quote
  async setSelectQuote({ appRef, dedRef, quoteRef, planRef }: TSelectQuote) {
    const body = {
      ApplicationReference: appRef,
      deductibleReference: dedRef,
      quotationReference: quoteRef,
      planReference: planRef,
    };
    return await mbiHttp.post(MBI_ENDPOINTS.SELECT_QUOTE, body);
  },

  // GET MBI Application Summary
  async getMBIAppSummary(paymentReference: string) {
    return await mbiHttp.get(
      `${MBI_ENDPOINTS.GET_APP}${paymentReference}${MBI_ENDPOINTS.GET_APPLICATION_SUMMARY}`,
    );
  },

  // GET MBI Download Policy Document
  async getDownloadPolicyDocument(appRef: string) {
    return await mbiHttp.get(MBI_ENDPOINTS.GET_APPLICATION_POLICY_DPWNLOAD(appRef));
  },

  // GET Missing MBI Lookups
  async getLookupsMissingData() {
    return await mbiHttp.get(MBI_ENDPOINTS.GET_LOOKUPS_MISSING_DATA);
  },

  // PUT Missing MBI data
  async submitMissingData(data: TMissingData, paymentReference: string) {
    const body = {
      ...data,
    };
    return await mbiHttp.put(`${MBI_ENDPOINTS.GET_APP}${paymentReference}`, body);
  },

  async getSequencesIds(id: string) {
    if (authToken || localStorage.getItem('token')) {
      return await mbiHttp.get(MBI_ENDPOINTS.GET_SEQUENCES_IDS, {
        policyHolderId: id,
      });
    } else {
      return { suggestedSequences: [] };
    }
  },
};

export default services;
