import { Trans, useTranslation } from 'react-i18next';
import { IPageErrorMessage } from '@models/errorMessage.model';
import { ErrorKeys } from '@Services/errorKeys';
import { useEffect, useState } from 'react';
export const PageErrorMessage = ({
  inputKey,
  error,
  pageErrorMessageTestId,
  closeBtnTestId,
  phid,
}: IPageErrorMessage) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['translation']);
  const [isDismissed, setIsDismissed] = useState(false);
  useEffect(() => {
    setIsDismissed(false);
  }, [error]);

  return (
    <>
      {!isDismissed &&
        error?.map(
          (err) =>
            ErrorKeys.get(inputKey)?.split(',').includes(err) && (
              <div className='mt-3 order-10 w-full' data-testid={pageErrorMessageTestId} key={inputKey}>
                <div
                  className='flex w-full alert alert-danger items-start alert-mob fade show breakLine'
                  role='alert'
                >
                  <b className='w-full'>
                    {err === 'T0030' ? (
                      <Trans
                        i18nKey='translation:T0030'
                        components={{
                          contactUs: <a className='text-decoration-underline' href='/contact-us' />,
                        }}
                      />
                    ) : err === 'KYC0153' ? (
                      <Trans
                        i18nKey='translation:KYC0153'
                        components={{
                          br: <br />,
                        }}
                      />
                    ) : err === 'Watheq01' || err === 'Watheq02' ? (
                      t(`translation:${err}`, { policyId: phid })
                    ) : (
                      t(`translation:${err}`)
                    )}
                  </b>
                  <button
                    data-testid={closeBtnTestId}
                    type='button'
                    className={`close ${
                      language === 'en' ? 'right-0 left-auto' : 'right-auto left-0'
                    }`}
                    onClick={() => setIsDismissed(true)}
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
              </div>
            ),
        )}
    </>
  );
};
