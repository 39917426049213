import axios from 'axios';
import { setJwtToken } from './jwtTokenValidator';

export const logOff = async (failedRequest: any) => {
  const ele = window.parent.document.getElementById('Sesssion-expire-popup-trigger');
  ele && ele.click();
  lastRequest = failedRequest;
};

export let lastRequest: any = null;
export const reCallLastFailedRequest = () => {
  if (!lastRequest) return;

  setJwtToken(lastRequest.headers);

  axios.create().request(lastRequest);
};
