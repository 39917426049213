import { IButtonLoader } from '@models/button.model';
import Img from './Img';

export const ButtonLoader = ({ children, isLoading = false, ...props }: IButtonLoader) => {
  return (
    <button style={{ minWidth: 150 }} {...props} disabled={isLoading || props.disabled}>
      {!isLoading && children}
      {isLoading && (
        <Img
          src={'/Resources/images/loading.gif'}
          style={{ width: '28px' }}
          alt='Loader'
          title='Loader'
        />
      )}
    </button>
  );
};
