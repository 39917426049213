import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';

export const isEmpty = (val: string | any[] | undefined) => {
  if (val === undefined || val.length <= 0) {
    return true;
  }
  return false;
};

export const handleCopy = async (text: string | any) => {
  if (!isEmpty(text)) {
    await navigator.clipboard.writeText(text);
  }
};

export const preventOnClick = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
};

export const formatDate = (date: string) => {
  const { language } = i18n;
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return language === 'en' ? [day, month, year].join('-') : [year, month, day].join('-');
};

export const getDateFromString = (dateTimeStr: string) => {
  if (dateTimeStr) {
    return formatDate(dateTimeStr);
  }
  return '';
};

export const addCommaToValue = (str: string) => {
  return str?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeComma = (data: any) => {
  if (typeof data === 'number') {
    if (isNaN(data)) return 0;
    else return data;
  }
  return data?.replace(/,/g, '');
};

export const formatCurrencyWithoutFraction = (val: number) => {
  return addCommaToValue(val?.toFixed(0));
};

export const formatCurrency = (val: number) => {
  return addCommaToValue(val?.toFixed(2));
};

export const isHybridAppUserAgent = () => {
  let isHybridApp = false;
  let userAgent = '';
  try {
    userAgent = navigator.userAgent;
    const searchString = 'HybridApp'.toLowerCase();
    if (userAgent && searchString) {
      const arrUserAgentdetails = userAgent.split('|~|');
      if (
        userAgent.toLowerCase().indexOf(searchString) === 0 &&
        arrUserAgentdetails != null &&
        arrUserAgentdetails.length >= 7
      ) {
        isHybridApp = true;
      }
    }
  } catch (Exception) {
    isHybridApp = false;
  }
  return isHybridApp;
};

export const isDesktopApp = () => {
  return !isMobile && !isHybridAppUserAgent();
};

export const isDateTomorrow = (dateStr: string) => {
  const effectiveDateDay = new Date(dateStr).getDate();
  const effectiveDateMonth = new Date(dateStr).getMonth();
  const effectiveDateYear = new Date(dateStr).getFullYear();
  const tomorrowDay = new Date();
  tomorrowDay.setDate(tomorrowDay.getDate() + 1);
  const tomorrowMonth = tomorrowDay.getMonth();
  const tomorrowYear = tomorrowDay.getFullYear();

  return (
    effectiveDateDay == tomorrowDay.getDate() &&
    effectiveDateMonth == tomorrowMonth &&
    effectiveDateYear == tomorrowYear
  );
};

export const formatNumber = (n: number) => {
  return n > 9 ? '' + Math.floor(n) : Math.floor(n);
};

export const getFallbackLabelKey = (labelKey: string) => {
  switch (labelKey) {
    case 'nameEnglish':
      return 'nameArabic';
    case 'englishName':
      return 'arabicName';
    case 'mileageRangeEn':
      return 'mileageRangeAr';
    default:
      return labelKey;
  }
};

export const convertToWesternNumerals = (input: string) => {
  const arabicToWesternMap: any = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
  };

  const converted = input?.replace(/[\u0660-\u0669\u06F0-\u06F9]/g, function (match) {
    return arabicToWesternMap[match];
  });

  return converted?.replace(/[^0-9]/g, '');
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const hash = async (string: string) => {
  function buf2hex(buffer: ArrayBuffer) {
    return Array.prototype.map
      .call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2))
      .join('');
  }
  const inputBytes = new TextEncoder().encode(string);
  const hashBytes = await window.crypto.subtle.digest('SHA-256', inputBytes);
  return buf2hex(hashBytes);
};

export const useGenericDayTranslate = () => {
  const { t } = useTranslation('translation');
  const genericDayTranslate = (type: string, num: number) => {
    switch (type) {
      case 'day':
        if (num == 1 || num > 10) {
          return num > 10 ? t('translation:day_more_10', { num }) : t('translation:day');
        } else if (num == 2) {
          return t('translation:2day');
        } else if (num > 2 && num <= 10) {
          return t('translation:days', { num });
        }
        break;
      case 'hour':
        if (num == 1 || num > 10) {
          return num > 10 ? t('translation:hour_more_10', { num }) : t('translation:hour');
        } else if (num == 2) {
          return t('translation:2hour');
        } else if (num > 2 && num <= 10) {
          return t('translation:hours', { num });
        }
        break;
      case 'minute':
        if (num == 1 || num > 10) {
          return num > 10 ? t('translation:minute_more_10', { num }) : t('translation:minute');
        } else if (num == 2) {
          return t('translation:2minute');
        } else if (num > 2 && num <= 10) {
          return t('translation:minutes', { num });
        }
        break;
      default:
        break;
    }
  };
  return { genericDayTranslate };
};
