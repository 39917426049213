import MBIServices from '@Services/mbi/application';
import useDocumentTitle from '@Hooks/useDocumentTitle';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GA4Events } from '@Services/ga4Events';
import { dataLayerPush, eCommmercePush, getEnglishFromKey } from '@Services/ga4';
import { ErrorsTypes, FLOWS, STEPS } from '@models/enums/ga4.enum';
import TameeniLoader from '@Components/common/TameeniLoader';
import NoData from '@Components/common/noData';
import { ButtonLoader } from '@Components/common/ButtonLoader';
import PolicyholderDetails from './policyholderDetails';
import VehicleDetails from './vehicleDetails';
import PolicyDetails from './PolicyDetails/PolicyDetails';
import SuccessIntro from './SuccessIntro';
import { TMBIApplicationSummary } from '@models/mbi.model';

export default function MBISuccess() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t, i18n } = useTranslation(['mbi', 'titles']);
  useDocumentTitle(t('titles:mbiStep4'));
  const [errorKey, setErrorKey] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicationRef, setApplicationRef] = useState<string>();
  const [applicationSummary, setApplicationSummary] = useState<TMBIApplicationSummary>();
  const [homepageLoading, setHomepageLoading] = useState<boolean>(false);

  // Get Application Summary
  const getApplicationSummary = async () => {
    const paymentReference = params.get('paymentReference');
    if (paymentReference) {
      setIsLoading(true);
      const ga4Body = JSON.parse(localStorage.getItem('ga4Body') as string);
      try {
        const data = await MBIServices.getMBIAppSummary(paymentReference);
        if (data) {
          setApplicationRef(data?.applicationReference);

          setApplicationSummary(data?.applicationSummaryModel);
          setIsLoading(false);
          eCommmercePush(GA4Events.purchase.event, [
            {
              key: GA4Events.purchase.params.currency,
              value: 'sar',
            },
            {
              key: GA4Events.purchase.params.value,
              value: +data?.applicationSummaryModel?.invoice?.total,
            },
            {
              key: GA4Events.purchase.params.tax,
              value: +data?.applicationSummaryModel?.invoice?.vatAmount,
            },
            {
              key: GA4Events.purchase.params.transactionId,
              value: paymentReference,
            },
            {
              key: GA4Events.addPaymentInfo.params.items,
              value: [
                {
                  item_category: FLOWS.MBI,
                  currency: 'sar',
                  item_name: ga4Body?.quoteName,
                  item_id: ga4Body?.quoteId,
                  item_brand: 'Tawuniya',
                  flow_name: FLOWS.MBI,
                  price: +data?.applicationSummaryModel?.invoice?.total,
                  discount: '',
                  item_list_id: '',
                  item_list_name: '',
                },
              ],
            },
          ]);
        }
      } catch (err: any) {
        setIsLoading(false);
        setErrorKey(err?.response?.data?.errors);
        dataLayerPush(GA4Events.errorCaptured.event, [
          {
            key: GA4Events.errorCaptured.params.flowName,
            value: FLOWS.MBI,
          },
          {
            key: GA4Events.errorCaptured.params.stepName,
            value: STEPS.SUCCESS_PAGE,
          },
          {
            key: GA4Events.errorCaptured.params.errorCode,
            value: '400',
          },
          {
            key: GA4Events.errorCaptured.params.errorMessage,
            value:
              i18n.language === 'en'
                ? t(`common:${err?.response?.data?.errors?.[0]}`)
                : await getEnglishFromKey(err?.response?.data?.errors?.[0]),
          },
          {
            key: GA4Events.errorCaptured.params.type,
            value: ErrorsTypes.REQUEST,
          },
        ]);
      }
    }
  };
  // Call Get Application Summary
  useEffect(() => {
    getApplicationSummary();
  }, []);

  return (
    <section className='container page-section'>
      {isLoading ? (
        <TameeniLoader />
      ) : applicationSummary && !errorKey?.length ? (
        <div className='flex flex-col gap-7 border-0 border-t-[5px] border-solid border-secondary-100 p-5 bg-gradient-to-b from-[#e4f5fe] to-15%  to-white bg-gray-light2 shadow-[0_0_15px_0_rgba(0,0,0,0.16)]'>
          <SuccessIntro appRef={applicationRef} applicationSummary={applicationSummary} />
          {/* <Coupon /> */}
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            <PolicyholderDetails policyholderData={applicationSummary?.policyHolder} />
            <VehicleDetails vehicleData={applicationSummary?.vehicleSummary} />
          </div>

          <PolicyDetails
            policyData={applicationSummary?.policyDetails}
            invoiceData={applicationSummary?.invoice}
            deductible={applicationSummary?.deductible}
            insuranceType={applicationSummary?.insuranceType}
            insuranceTypeAr={applicationSummary?.insuranceTypeAr}
            features={applicationSummary?.features}
          />

          <ButtonLoader
            type='button'
            className='block w-full md:w-auto m-auto ta btn btn-secondary px-4'
            onClick={() => {
              setHomepageLoading(!homepageLoading);
              navigate('/');
            }}
            isLoading={homepageLoading}
          >
            {t('mbi:home_page')}
          </ButtonLoader>
        </div>
      ) : (
        <div className='my-20'>
          <NoData errorKey={errorKey} />
        </div>
      )}
    </section>
  );
}
