import { TNoData } from '@models/generalCommon.model';
import { useTranslation } from 'react-i18next';
import Img from './Img';

const NoData = ({ errorKey }: TNoData) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className='relative flex flex-col items-center justify-center mt-5 py-4'>
      <div className='page-alert text-center ta text-danger'>
        <div className='icon-alert'>
          <Img src={'/Resources/images/error-icon.svg'} alt='' title='' />
        </div>
        <h4>{t('translation:sorry_it_appears_that')}:</h4>
        <p>
          <span className='block'>{t(`translation:${errorKey}`)}</span>
          <span className='block'>
            {t('translation:error_code')}: <b className='ta text-dark'>{errorKey}</b>
          </span>
        </p>
      </div>
    </div>
  );
};

export default NoData;
