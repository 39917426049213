import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IAddonsApp,
  TAddonsMBIDeductible,
  TAddonsMBIPlan,
  TApplicationAddonsRefObj,
  TAddonsInvoiceBody,
  TFlexFeature,
  TMalathFeatures,
  TMalathODDeductible,
  VasAddonDetails,
} from '@models/addons.model';
import type { RootState } from '@Redux/store';

type TAddons = {
  isCompany: boolean;
  isDimmed: boolean;
  addonsApp: IAddonsApp | null;
  selectedDidctuble: TAddonsMBIDeductible | null;
  selectedPlan: TAddonsMBIPlan | null;
  addonsInvoiceBody: TAddonsInvoiceBody | null;
  applicationAddonsRefObj: TApplicationAddonsRefObj | null;
  flexSelectedFeatures: TFlexFeature[];
  malathPaidFeatures: TMalathFeatures[];
  malathSelectedDedctuble: TMalathODDeductible | null;
  malathAddCart: boolean;
  vasAddonsSelected: VasAddonDetails[];
};

const initialState: TAddons = {
  isCompany: false,
  isDimmed: true,
  addonsApp: null,
  selectedDidctuble: null,
  selectedPlan: null,
  addonsInvoiceBody: null,
  applicationAddonsRefObj: null,
  flexSelectedFeatures: [],
  malathPaidFeatures: [],
  malathSelectedDedctuble: null,
  malathAddCart: false,
  vasAddonsSelected: [],
};

const addonsSlice = createSlice({
  name: 'addons',
  initialState,
  reducers: {
    setIsCompany: (state, action: PayloadAction<TAddons['isCompany']>) => {
      state.isCompany = action.payload;
    },
    setIsDimmed: (state, action: PayloadAction<TAddons['isDimmed']>) => {
      state.isDimmed = action.payload;
    },
    getaddonsApplication: (state, action: PayloadAction<TAddons['addonsApp']>) => {
      state.addonsApp = action.payload;
      state.flexSelectedFeatures =
        action.payload?.flexAddonDetails?.motorFlexFeatures?.filter((f) => f.isSelected) ?? [];
      if (!action.payload?.addOnsMBI?.selectedMBIAddon) {
        state.selectedDidctuble = null;
      }
      state.vasAddonsSelected = action.payload?.vasAddons?.filter((v) => v.isSelected) ?? [];
    },
    setSelectedDiductibule: (state, action: PayloadAction<TAddons['selectedDidctuble']>) => {
      state.selectedDidctuble = action.payload;
    },
    setChoosenPlan: (state, action: PayloadAction<TAddons['selectedPlan']>) => {
      state.selectedPlan = action.payload;
    },
    setAddonsInvoiceBody: (state, action: PayloadAction<TAddons['addonsInvoiceBody']>) => {
      state.addonsInvoiceBody = action.payload;
    },
    setApplicationAddonsRef: (state, action: PayloadAction<TAddons['applicationAddonsRefObj']>) => {
      state.applicationAddonsRefObj = action.payload;
    },
    setFlexSelectedFeatures: (state, action: PayloadAction<TAddons['flexSelectedFeatures']>) => {
      state.flexSelectedFeatures = action.payload;
    },
    setVasAddonsSelected: (state, action: PayloadAction<TAddons['vasAddonsSelected']>) => {
      state.vasAddonsSelected = action.payload;
    },
    setMalathPaidFeatures: (state, action: PayloadAction<TAddons['malathPaidFeatures']>) => {
      state.malathPaidFeatures = action.payload;
    },
    setMalathSelectedDedctuble: (
      state,
      action: PayloadAction<TAddons['malathSelectedDedctuble']>,
    ) => {
      state.malathSelectedDedctuble = action.payload;
    },
    setMalathAddCart: (state, action: PayloadAction<TAddons['malathAddCart']>) => {
      state.malathAddCart = action.payload;
    },
  },
});

export const {
  setIsCompany,
  setIsDimmed,
  getaddonsApplication,
  setSelectedDiductibule,
  setChoosenPlan,
  setAddonsInvoiceBody,
  setApplicationAddonsRef,
  setFlexSelectedFeatures,
  setVasAddonsSelected,
  setMalathPaidFeatures,
  setMalathSelectedDedctuble,
  setMalathAddCart,
} = addonsSlice.actions;

export const isCompany = (state: RootState) => state.addons.isCompany;
export const isDimmed = (state: RootState) => state.addons.isDimmed;
export const addonsApplication = (state: RootState) => state.addons.addonsApp;
export const selectedDidctuble = (state: RootState) => state.addons.selectedDidctuble;
export const selectedPlan = (state: RootState) => state.addons.selectedPlan;
export const addonsInvoiceBody = (state: RootState) => state.addons.addonsInvoiceBody;
export const applicationAddonsRefObj = (state: RootState) => state.addons.applicationAddonsRefObj;
export const flexSelectedFeatures = (state: RootState) => state.addons.flexSelectedFeatures;
export const vasAddonsSelected = (state: RootState) => state.addons.vasAddonsSelected;
export const malathPaidFeatures = (state: RootState) => state.addons.malathPaidFeatures;
export const malathSelectedDedctuble = (state: RootState) => state.addons.malathSelectedDedctuble;
export const malathAddCart = (state: RootState) => state.addons.malathAddCart;

export default addonsSlice.reducer;
