import axios from '@Middleware/mbi.interceptor';

const mbiHttp = {
  async get(path: string, params: any = null) {
    return (await axios.get(path, { params }))?.data;
  },
  async post(path: string, body: any, params: any = null) {
    return (await axios.post(path, body, { params }))?.data;
  },
  async put(path: string, body: any, params: any = null) {
    return (await axios.put(path, body, { params }))?.data;
  },
  async delete(path: string, params: any = null) {
    return (await axios.delete(path, { params }))?.data;
  },

  async postCorsRequest(baseURL: string, params: any = {}) {
    const formdata = new FormData();
    for (const key in params) {
      formdata.append(key, params[key]);
    }
    const response = await fetch(baseURL, {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    });
    if (response && response.status === 200) {
      return await response.json();
    } else {
      return null;
    }
  },
  async getCorsRequest(baseURL: string, params: any = {}) {
    let queryParams = '';
    if (params) {
      for (const key in params) {
        queryParams += key + '=' + params[key] + '&';
      }
      if (queryParams.length > 0) {
        queryParams = '?' + queryParams;
        if (queryParams.endsWith('&')) {
          queryParams = queryParams.substring(0, queryParams.length - 1);
        }
      }
    }

    const response = await fetch(`${baseURL}${queryParams}`, {
      method: 'GET',
      redirect: 'follow',
    });
    if (response && response.status === 200) {
      return await response.json();
    } else {
      return null;
    }
  },
};

export default mbiHttp;
