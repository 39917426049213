import Img from '@Components/common/Img';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import useModal from '@Hooks/useModal';
import { TMBIApplicationSummary, TMBIPriceItem } from '@models/mbi.model';
import { ButtonLoader } from '@Components/common/ButtonLoader';
import { formatCurrency } from 'src/utils/commonUtils';

type Props = {
  applicationSummary: TMBIApplicationSummary;
  DownloadPolicyPaper: () => void;
  isLoading: boolean;
};
export default function PaymentReceiptPopup({
  applicationSummary,
  DownloadPolicyPaper,
  isLoading,
}: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['mbi', 'translation']);
  const [domReady, setDomReady] = useState(false);
  const { handleCloseModal } = useModal();

  const { policyHolder, vehicleSummary, policyDetails, invoice, deductible } = applicationSummary ?? {};
  useEffect(() => {
    setDomReady(true);
  }, []);

  const element = (
    <div
      className='modal custom-popup print-receipt-popup py-6 modal is-default custom-popup print-receipt-popup show'
      id='payment-receipt-popup'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='custom-popup'
    >
      <div
        className='relative modal-dialog  modal-dialog modal-dialog-show'
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <div className='modal-content py-8'>
          <div className='modal-header top-2'>
            <button type='button' className='close' onClick={handleCloseModal}>
              {' '}
              <Img src={'/Resources/images/popup-close-icon.png'} alt='' />
            </button>
          </div>
          <div className='modal-body'>
            <div className='modal-head'>
              <h4 className='text-center'>{t('mbi:policy_details')}</h4>
            </div>
            <section className='flex flex-col lg:flex-row gap-4 pt-5'>
              <div
                className='flex flex-col gap-4 w-full lg:w-2/3 bg-white shadow-[0_0_15px_0_rgba(0,0,0,0.16)] rounded-[20px] py-5 px-4'
                data-testid='policy-holder-details'
              >
                <div className='flex gap-4 flex-col md:flex-row'>
                  {/* Policyholder */}
                  <div className='w-full'>
                    <h4
                      className='border-b border-0 border-solid border-gray-color-400 text-xl pb-3'
                      data-testid='policy-holder-title'
                    >
                      {t('mbi:policy_holder_details')}
                    </h4>

                    <div className='p-3 text-[14px]' data-testid='policy-holder-data'>
                      <div className='flex' data-testid='policy-holder-id-container'>
                        <span className='w-1/2 font-bold' data-testid='policy-holder-id-label'>
                          {t('mbi:policyholder_ID')}
                        </span>
                        <span className='w-1/2' data-testid='policy-holder-id-value'>
                          {policyHolder?.identityNumber ?? '---'}
                        </span>
                      </div>

                      <div className='flex' data-testid='policy-holder-name-container'>
                        <span className='w-1/2 font-bold' data-testid='policy-holder-name-label'>
                          {t('mbi:Name')}
                        </span>
                        <span
                          className='w-1/2'
                          data-testid='policy-holder-name-value'
                          data-hj-suppress
                        >
                          {policyHolder?.fullName ?? '---'}
                        </span>
                      </div>

                      <div
                        className='flex'
                        data-testid='policy-holder-dateOfBirth-container'
                      >
                        <span
                          className='w-1/2 font-bold'
                          data-testid='policy-holder-dateOfBirth-label'
                        >
                          {t('mbi:date_of_birth')}
                        </span>
                        <span className='w-1/2' data-testid='policy-holder-dateOfBirth-value'>
                          {policyHolder?.dateOfBirth ?? '---'}
                        </span>
                      </div>

                      <div
                        className='flex'
                        data-testid='policy-holder-national-address-container'
                      >
                        <span
                          className='w-1/2 font-bold'
                          data-testid='policy-holder-national-address-label'
                        >
                          {t('mbi:national_address')}
                        </span>
                        <div
                          className='w-1/2'
                          data-testid='policy-holder-national-address-value'
                          data-hj-suppress
                        >
                          {policyHolder?.fullAddress?.buildingNumber && (
                            <span>
                              {t('mbi:building')}: {policyHolder?.fullAddress?.buildingNumber} ,{' '}
                            </span>
                          )}
                          <span>
                            {policyHolder?.fullAddress?.city} ,{' '}
                            {policyHolder?.fullAddress?.district} ,{' '}
                            {policyHolder?.fullAddress?.street} ,{' '}
                          </span>
                          {policyHolder?.fullAddress?.additionalNumber && (
                            <span>
                              {t('mbi:secondary_number')}:{' '}
                              {policyHolder?.fullAddress?.additionalNumber} ,{' '}
                            </span>
                          )}
                          {policyHolder?.fullAddress?.zipCode && (
                            <span>
                              {t('mbi:post_code')}: {policyHolder?.fullAddress?.zipCode}
                            </span>
                          )}
                        </div>
                      </div>

                      <div
                        className='flex'
                        data-testid='policy-holder-mobile-number-container'
                      >
                        <span
                          className='w-1/2 font-bold'
                          data-testid='policy-holder-mobile-number-label'
                        >
                          {t('mbi:mobile_number')}
                        </span>
                        <span className='w-1/2' data-testid='policy-holder-mobile-number-value'>
                          {policyHolder?.mobileNumber ?? '---'}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Vehicle */}
                  <div className='w-full'>
                    <h4
                      className='border-b border-0 border-solid border-gray-color-400 text-xl pb-3'
                      data-testid='vehicle-title'
                    >
                      {t('mbi:vehicle_details')}
                    </h4>
                    <div className='p-3 text-[14px]' data-testid='vehicle-data'>
                      <div
                        className='flex'
                        data-testid='vehicle-sequence-number-container'
                      >
                        <span
                          className='w-1/2 font-bold'
                          data-testid='vehicle-sequence-number-label'
                        >
                          {t('translation:sequence_number')}
                        </span>
                        <span className='w-1/2' data-testid='vehicle-sequence-number-value'>
                          {vehicleSummary?.sequenceNumber ?? '---'}
                        </span>
                      </div>
                      <div className='flex' data-testid='vehicle-make-model-container'>
                        <span className='w-1/2 font-bold' data-testid='vehicle-make-model-label'>
                          {t('mbi:vehicle_make_model')}
                        </span>
                        <span className='w-1/2' data-testid='vehicle-make-model-value'>
                          {`${vehicleSummary?.make ?? '---'} / ${vehicleSummary?.model ?? '---'}`}
                        </span>
                      </div>

                      <div
                        className='flex'
                        data-testid='vehicle-plate-number-container'
                      >
                        <span className='w-1/2 font-bold' data-testid='vehicle-plate-number-label'>
                          {t('mbi:plate_number')}
                        </span>
                        <span className='w-1/2' data-testid='vehicle-plate-number-value'>
                          {language === 'en'
                            ? vehicleSummary?.plateNumberArabic ?? '---'
                            : vehicleSummary?.plateNumberEnglish ?? '---'}
                        </span>
                      </div>

                      <div
                        className='flex'
                        data-testid='vehicle-manufacturing-year-container'
                      >
                        <span
                          className='w-1/2 font-bold'
                          data-testid='vehicle-manufacturing-yea-label'
                        >
                          {t('mbi:year_of_manufacturing')}
                        </span>
                        <span className='w-1/2' data-testid='vehicle-manufacturing-yea-value'>
                          {vehicleSummary?.manufactureYear ?? '---'}
                        </span>
                      </div>

                      <div className='flex' data-testid='vehicle-color-container'>
                        <span className='w-1/2 font-bold' data-testid='vehicle-color-label'>
                          {t('mbi:color')}
                        </span>
                        <span className='w-1/2' data-testid='vehicle-color-value'>
                          {vehicleSummary?.majorColor ?? '---'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* IC */}
                <div className='flex flex-col sm:flex-row items-center gap-2'>
                  <Img
                    src='/Resources/images/ICLogos/tawuniya-updated.png'
                    alt='IC Logo'
                    data-testid='policy-IC-logo'
                  />
                  <div className='flex flex-col items-start'>
                    <b> {t('mbi:tawnia_insurance')}</b>
                    <span className='ta text-gray logo-desc'>{t('mbi:mbi_insurance_title')}</span>
                  </div>
                </div>
                {/* Policy */}
                <div
                  data-testid='purchased-policy-details'
                  className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 w-full text-size-13 border-0 border-t border-solid border-gray-color-400 p-4'
                >
                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-issue-date-container'
                  >
                    <span className='font-bold' data-testid='policy-issue-date-label'>
                      {t('mbi:Policy_Issue_Date')}
                    </span>
                    <span data-testid='policy-issue-date-value'>
                      {policyDetails?.policyIssueDate ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-effective-date-container'
                  >
                    <span className='font-bold' data-testid='policy-effective-date-label'>
                      {t('mbi:policy_effective_date')}
                    </span>
                    <span data-testid='policy-effective-date-value'>
                      {policyDetails?.effectiveDate ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-expiry-date-container'
                  >
                    <span className='font-bold' data-testid='policy-expiry-date-label'>
                      {t('mbi:policy_expiry_date')}
                    </span>
                    <span data-testid='policy-expiry-date-value'>
                      {policyDetails?.expiryDate ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-number-container'
                  >
                    <span className='font-bold' data-testid='policy-number-label'>
                      {t('mbi:policy_number')}
                    </span>
                    <span data-testid='policy-number-value'>
                      {policyDetails?.policyNumber ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-quote-reference-container'
                  >
                    <span className='font-bold' data-testid='policy-quote-reference-label'>
                      {t('mbi:quote_reference')}
                    </span>
                    <span data-testid='policy-quote-reference-value'>
                      {policyDetails?.quoteReference ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-insurance-type-container'
                  >
                    <span className='font-bold' data-testid='policy-insurance-type-label'>
                      {t('mbi:insurance_type')}
                    </span>
                    <span data-testid='policy-insurance-type-value'>
                      {language === 'en'
                        ? applicationSummary?.insuranceType
                        : applicationSummary?.insuranceTypeAr}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-deductible-amount-container'
                  >
                    <span className='font-bold' data-testid='policy-deductible-amount-label'>
                      {t('mbi:deductible_amount')}
                    </span>
                    <span data-testid='policy-deductible-amount-value'>
                      {deductible ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-payment-date-container'
                  >
                    <span className='font-bold' data-testid='policy-payment-date-label'>
                      {t('mbi:payment_date')}
                    </span>
                    <span data-testid='policy-payment-date-value'>
                      {policyDetails?.paymentDate ?? '---'}
                    </span>
                  </div>

                  <div
                    className='flex flex-col justify-between'
                    data-testid='policy-receipt-number-container'
                  >
                    <span className='font-bold' data-testid='policy-receipt-number-label'>
                      {t('mbi:receipt_number')}
                    </span>
                    <span data-testid='policy-receipt-number-value'>
                      {policyDetails?.receiptNumber ?? '---'}
                    </span>
                  </div>
                </div>
              </div>
              {/* Receipt */}
              <div className='payment-box w-full lg:w-1/3'>
                <h4
                  className='text-lg text-primary border-0 border-b border-solid border-primary pb-2'
                  data-testid={'MoreDetailsLabel'}
                >
                  {t('mbi:summary_details')}
                </h4>
                <div className='flex justify-between font-bold'>
                  <span className='font-medium text-base' data-testid={'priceAmountLabel'}>
                    {t('mbi:policy_price_amount')}
                  </span>
                  <span className='font-medium text-base' data-testid={'priceAmount'}>
                    ({formatCurrency(invoice?.policyPriceAmount) ?? '---'} {t('mbi:SAR')})
                  </span>
                </div>
                <div className='flex font-bold mt-2'>
                  <span className='font-medium text-base' data-testid={'includesFollowingText'}>
                    {t('mbi:includes_the_following')}
                  </span>
                </div>
                <div className='flex flex-col justify-between font-bold'>
                  {invoice?.discounts?.map((discount: TMBIPriceItem) => (
                    <div
                      key={`discount-${discount?.nameEnglish}`}
                      className='flex justify-between my-1 w-full'
                    >
                      <span
                        className='font-medium text-base'
                        data-testid={`discount-${discount?.nameEnglish}-label`}
                      >
                        {language === 'en' ? discount?.nameEnglish : discount?.name}
                      </span>
                      <span
                        className='font-medium text-base  text-end	'
                        data-testid={`discount-${discount?.nameEnglish}`}
                      >
                        ({formatCurrency(discount?.amount) ?? '---'} {t('mbi:SAR')})
                      </span>
                    </div>
                  ))}
                </div>

                <div className='flex flex-col justify-between font-bold pb-4 border-0 border-b border-solid border-gray-200'>
                  {invoice?.breakdowns?.map((breakDown: TMBIPriceItem) => (
                    <div
                      key={`breakDown-${breakDown?.nameEnglish}`}
                      className='flex justify-between my-1 w-full'
                    >
                      <span
                        className='font-medium text-base w-4/6'
                        data-testid={`breakDown-${breakDown?.nameEnglish}-label`}
                      >
                        {language === 'en' ? breakDown?.nameEnglish : breakDown?.name}
                      </span>
                      <span
                        className='font-medium text-base w-2/6 text-end	'
                        data-testid={`breakDown-${breakDown?.nameEnglish}`}
                      >
                        ({formatCurrency(breakDown?.amount) ?? '---'} {t('mbi:SAR')})
                      </span>
                    </div>
                  ))}
                </div>
                <div className='flex justify-between font-bold'>
                  <span className='font-medium text-base' data-testid={'subTotalLabel'}>
                    {t('mbi:sub_total')}
                  </span>
                  <span className='font-medium text-base' data-testid={'subTotal'}>
                    ({formatCurrency(invoice?.policyPriceAmount) ?? '---'} {t('mbi:SAR')})
                  </span>
                </div>
                <div className='flex justify-between font-bold pb-4 border-0 border-b border-solid border-gray-200'>
                  <span className='font-medium text-base' data-testid={'vatLabel'}>
                    {t('mbi:VAT')} ({invoice?.vatPercentage ?? '---'}%)
                  </span>
                  <span className='font-medium text-base' data-testid={'vat'}>
                    ({formatCurrency(invoice?.vatAmount) ?? '---'} {t('mbi:SAR')})
                  </span>
                </div>
                <div className='total-amount'>
                  <div className='flex justify-between font-bold my-2'>
                    <span
                      className='font-semibold text-xl text-black'
                      data-testid={'totalAmountLabel'}
                    >
                      {t('mbi:total_amount')}
                    </span>
                    <div
                      data-testid={'totalAmount'}
                      className='price-box text-end text-primary font-bold text-2xl'
                    >
                      ({invoice?.total ?? '---'} <sub className='text-black'>{t('mbi:SAR')}</sub>)
                    </div>
                  </div>
                </div>
                <span className='text-xs'>
                  {t('mbi:this_includes_taxes', {
                    amount: invoice?.brokerCommission ?? '---',
                  })}
                </span>
              </div>
            </section>

            <ButtonLoader
              className='block w-full md:w-auto m-auto ta btn btn-secondary px-4 mt-5'
              onClick={DownloadPolicyPaper}
              isLoading={isLoading}
            >
              {t('mbi:download')}
            </ButtonLoader>
          </div>
        </div>
      </div>
    </div>
  );
  return domReady ? ReactDOM.createPortal(element, document.getElementById('portal-modal')!) : null;
}
