import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@Redux/store';
import { IMBIInitialState } from '@models/mbi.model';

const initialState: IMBIInitialState = {
  next: false,
  application: null,
  policyHolderAndVehicle: null,
  invoiceReference: null,
};

export const mbiSlice = createSlice({
  name: 'MBI',
  initialState,
  reducers: {
    setNext: (state, action: PayloadAction<IMBIInitialState['next']>) => {
      state.next = action.payload;
    },
    setApplication: (state, action: PayloadAction<IMBIInitialState['application']>) => {
      state.application = action.payload;
    },
    setPolicyHolderAndVehicle: (
      state,
      action: PayloadAction<IMBIInitialState['policyHolderAndVehicle']>,
    ) => {
      state.policyHolderAndVehicle = action.payload;
    },
    setInvoiceReference: (state, action: PayloadAction<IMBIInitialState['invoiceReference']>) => {
      state.invoiceReference = action.payload;
    },
  },
});

export const { setNext, setApplication, setPolicyHolderAndVehicle, setInvoiceReference } =
  mbiSlice.actions;

export const selectNext = (state: RootState) => state.mbi.next;
export const selectApplication = (state: RootState) => state.mbi.application;
export const selectPolicyHolderAndVehicle = (state: RootState) => state.mbi.policyHolderAndVehicle;
export const selectInvoiceReference = (state: RootState) => state.mbi.invoiceReference;

export default mbiSlice.reducer;
