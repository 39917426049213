import { TMBIPolicyHolder } from '@models/mbi.model';
import { useTranslation } from 'react-i18next';

type Props = {
  policyholderData: TMBIPolicyHolder | undefined;
};

export default function PolicyholderDetails({ policyholderData }: Props) {
  const { t } = useTranslation(['mbi']);
  return (
    <section
      className='bg-white shadow-[0_0_15px_0_rgba(0,0,0,0.16)] rounded-[20px] py-5 px-4'
      data-testid='policy-holder-details'
    >
      <h4
        className='border-b border-0 border-solid border-gray-color-400 text-xl pb-3'
        data-testid='policy-holder-title'
      >
        {t('mbi:policy_holder_details')}
      </h4>

      <div className='p-3 text-[14px]' data-testid='policy-holder-data'>
        <div className='flex items-center' data-testid='policy-holder-id-container'>
          <span className='w-1/2 font-bold' data-testid='policy-holder-id-label'>
            {t('mbi:policyholder_ID')}
          </span>
          <span className='w-1/2' data-testid='policy-holder-id-value'>
            {policyholderData?.identityNumber ?? '---'}
          </span>
        </div>

        <div className='flex items-center' data-testid='policy-holder-name-container'>
          <span className='w-1/2 font-bold' data-testid='policy-holder-name-label'>
            {t('mbi:Name')}
          </span>
          <span className='w-1/2' data-testid='policy-holder-name-value' data-hj-suppress>
            {policyholderData?.fullName ?? '---'}
          </span>
        </div>

        <div className='flex items-center' data-testid='policy-holder-dateOfBirth-container'>
          <span className='w-1/2 font-bold' data-testid='policy-holder-dateOfBirth-label'>
            {t('mbi:date_of_birth')}
          </span>
          <span className='w-1/2' data-testid='policy-holder-dateOfBirth-value'>
            {policyholderData?.dateOfBirth ?? '---'}
          </span>
        </div>

        <div className='flex items-center' data-testid='policy-holder-national-address-container'>
          <span className='w-1/2 font-bold' data-testid='policy-holder-national-address-label'>
            {t('mbi:national_address')}
          </span>
          <div
            className='w-1/2'
            data-testid='policy-holder-national-address-value'
            data-hj-suppress
          >
            {policyholderData?.fullAddress?.buildingNumber && (
              <span>
                {t('mbi:building')}: {policyholderData?.fullAddress?.buildingNumber} ,{' '}
              </span>
            )}
            <span>
              {policyholderData?.fullAddress?.city} , {policyholderData?.fullAddress?.district} ,{' '}
              {policyholderData?.fullAddress?.street} ,{' '}
            </span>
            {policyholderData?.fullAddress?.additionalNumber && (
              <span>
                {t('mbi:secondary_number')}: {policyholderData?.fullAddress?.additionalNumber} ,{' '}
              </span>
            )}
            {policyholderData?.fullAddress?.zipCode && (
              <span>
                {t('mbi:post_code')}: {policyholderData?.fullAddress?.zipCode}
              </span>
            )}
          </div>
        </div>

        <div className='flex items-center' data-testid='policy-holder-mobile-number-container'>
          <span className='w-1/2 font-bold' data-testid='policy-holder-mobile-number-label'>
            {t('mbi:mobile_number')}
          </span>
          <span className='w-1/2' data-testid='policy-holder-mobile-number-value'>
            {policyholderData?.mobileNumber ?? '---'}
          </span>
        </div>
      </div>
    </section>
  );
}
