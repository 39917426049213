import i18n from 'src/i18n';
import Img from './Img';

interface ILoader extends React.HTMLAttributes<HTMLDivElement> {
  isPaymentLoader?: boolean;
}

export default function TameeniLoader({ isPaymentLoader, ...rest }: ILoader) {
  const getLoaderImg = () => {
    if (isPaymentLoader) {
      return i18n.language === 'en'
        ? '/Resources/images/agreement-loader.svg'
        : '/Resources/images/agreement-loader-ar.svg';
    } else {
      return '/Resources/images/loader.svg';
    }
  };
  return (
    <div {...rest} className={`tameeniLoader ${isPaymentLoader? 'bg-white-opacity' :''} ` }>
      <Img src={getLoaderImg()} alt='Tameeni Loader' title='Tameeni Loader' />
    </div>
  );
}
