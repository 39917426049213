import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { logOff } from '../services/logoffService';
import i18n from 'src/i18n';
import { toast } from 'react-toastify';
import { setJwtToken } from '@Services/jwtTokenValidator';

const instance = axios.create({
  baseURL: window.settings.VITE_MBI_BASE_URL,
});

instance.interceptors.request.use((request) => {
  request.headers.isMobile = false;
  request.headers.lang = i18n.language;
  const sessionReference = localStorage.getItem('sessionReference') ?? uuidv4();
  request.headers.sessionReference = sessionReference;
  localStorage.setItem('sessionReference', sessionReference);

  const appVersion = window.settings.VITE_VERSION;
  request.headers.appVersion = appVersion;

  setJwtToken(request.headers);


  return request;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status >= 500) toast(i18n.t('translation:general_error'));
    if (error.response?.status === 401) {
      await logOff(error.config);
    }else if(error.response?.status === 400 && error.response.data.errorCode === 'ISrvNA'){
      toast(i18n.t('translation:general_error'));
    }
    throw error;
  },
);

export default instance;
