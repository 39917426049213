import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export default function useUTM() {
  const { i18n } = useTranslation();
  const [params] = useSearchParams();
  const [version, setVersion] = useState(Infinity);
  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    //FETCH UTM PARAMS AND SAVE TO SESSION STORAGE
    params.get('utm_source') &&
      sessionStorage.setItem('utmSource', params.get('utm_source') as string);
    params.get('utm_medium') &&
      sessionStorage.setItem('utmMedium', params.get('utm_medium') as string);
    params.get('utm_campaign') &&
      sessionStorage.setItem('utmCampaign', params.get('utm_campaign') as string);
    params.get('utm_term') && sessionStorage.setItem('utmTerm', params.get('utm_term') as string);
  }, [
    params.get('utm_source'),
    params.get('utm_medium'),
    params.get('utm_campaign'),
    params.get('utm_term'),
  ]);
  useEffect(() => {
    setVersion(Number(navigator.userAgent.match(/\|~\|(\d+\.\d+)\|~\|/)?.[1]));
    setIsIOS(navigator.userAgent.includes('iOS'));

    //FETCH UTM PARAMS AND SAVE TO SESSION STORAGE
    params.get('utm_source') &&
      sessionStorage.setItem('utmSource', params.get('utm_source') as string);
    params.get('utm_medium') &&
      sessionStorage.setItem('utmMedium', params.get('utm_medium') as string);
    params.get('utm_campaign') &&
      sessionStorage.setItem('utmCampaign', params.get('utm_campaign') as string);
    params.get('utm_term') && sessionStorage.setItem('utmTerm', params.get('utm_term') as string);

    //GOOGLE REFERAL
    if (document.referrer.includes('google')) {
      sessionStorage.setItem('utmSource', 'google');
      //CHECK FOR PAID AD
      if (params.get('gclid') && (params.get('gclid') as string)?.length > 0) {
        sessionStorage.setItem('utmMedium', 'cpc');
      } else {
        sessionStorage.setItem('utmMedium', 'organic');
      }
    }
    //BING REFERAL
    else if (document.referrer.includes('bing')) {
      sessionStorage.setItem('utmSource', 'bing');
      //CHECK FOR PAID AD
      if (params.get('msclid') && (params.get('msclid') as string)?.length > 0) {
        sessionStorage.setItem('utmMedium', 'cpc');
      } else {
        sessionStorage.setItem('utmMedium', 'organic');
      }
    }
    // SET DEFAULT LANGUAGE
    i18n.changeLanguage(i18n.language === 'en' ? 'en' : 'ar');
  }, []);

  return { version, isIOS };
}
