import Img from '@Components/common/Img';
import { TMBIPolicyFeatures } from '@models/mbi.model';
import { useTranslation } from 'react-i18next';

type Props = {
  features: TMBIPolicyFeatures[] | undefined;
};
export default function PolicyFeatures({ features }: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['mbi']);

  return (
    <section
      className='flex items-center flex-col md:flex-row w-full lg:w-[47%] mt-4'
      data-testid='policy-IC-container'
    >
      <Img
        src='/Resources/images/ICLogos/tawuniya-updated.png'
        alt='IC Logo'
        data-testid='policy-IC-logo'
      />
      <div className='shadow-[0_-3px_6px_-3px_rgba(0,0,0,0.13))] md:shadow-[8px_0_6px_-6px_rgba(0,0,0,0.2)]'>
        <h4 className='mt-4 md:mt-0 ms-2 mb-2'>{t('mbi:insurance_with_following')}</h4>
        {features?.map((feature: TMBIPolicyFeatures) => {
          return (
            <div className='flex items-baseline mt-4 md:mt-0 ms-2 mb-2' key={feature?.referenceNo}>
              <span className='align-middle'>
                {language === 'en'
                  ? feature?.descriptionEnglish
                  : feature?.descriptionArabic ?? '---'}
                {' , '}
                {feature?.coverageLimit}
              </span>
            </div>
          );
        })}
      </div>
    </section>
  );
}
