import { QuoteTypes, RepairMethod } from '@models/enums/quoteList.enum';

export const GA4Events = {
  insuranceCategory: {
    event: 'insurance_category',
    params: { category: 'category' },
  },
  policyHolderAndVehicleDetails: {
    event: 'policyholder_and_vehicle_details',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      category: 'category',
    },
  },
  quoteType: {
    event: 'quote_type',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      category: 'category',
    },
  },
  availableFromMobile: {
    event: 'available_from_mobile',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  availableFromMobileContinue: {
    event: 'available_from_mobile_continue',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  filterUpdate: {
    event: 'filter_update',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      repairMethod: 'repair_method',
      estimatedValue: 'estimated_value',
    },
  },
  termsAndConditions: {
    event: 'terms_and_conditions',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      quote: 'quote',
      company: 'company',
    },
  },
  summaryCategoryInfo: {
    event: 'summary_category_info',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      category: 'category',
    },
  },
  printPolicyPaper: {
    event: 'print_policy_paper',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      quote: 'quote',
      company: 'company',
    },
  },
  printPolicyReceipt: {
    event: 'print_policy_receipt',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      quote: 'quote',
      company: 'company',
    },
  },
  profileInteractions: {
    event: 'profile_interactions',
    params: { clickText: 'click_text' },
  },
  startRenewal: {
    event: 'insurance_renewal_start',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      repairMethod: 'repair_method',
      insuranceType: 'insurance_type',
    },
  },
  editRenewalDetails: {
    event: 'edit_insurance_details_start',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      repairMethod: 'repair_method',
      insuranceType: 'insurance_type',
    },
  },
  downloadFiles: {
    event: 'file_download',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      fileTitle: 'file_title',
    },
  },
  editDetails: {
    event: 'edit_detail',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      textBoxTitle: 'text_box_title',
    },
  },
  quoteDetailsInteraction: {
    event: 'quote_details_interaction',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      quote: 'quote',
      company: 'company',
      action: 'action',
      category: 'category',
    },
  },
  shareLink: {
    event: 'share_link',
    params: { flowName: 'flow_name', stepName: 'step_name', method: 'method' },
  },
  copyLink: {
    event: 'copy_link',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  addBasicDataTry: {
    event: 'add_basic_data_try',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  addInsuranceDataTry: {
    event: 'add_insurance_data_try',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      purpose: 'purpose',
      insuranceType: 'insurance_type',
      car: 'car',
      modelYear: 'model_year',
      repairMethod: 'repair_method',
    },
  },
  payNowTry: {
    event: 'pay_now_try',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      category: 'category',
      quote: 'quote',
      company: 'company',
      paymentType: 'payment_type',
      totalAmount: 'total_amount',
      car: 'car',
      profitCommission: 'profit_commission',
    },
  },
  addPaymentInfo: {
    event: 'add_payment_info',
    params: {
      currency: 'currency',
      value: 'value',
      paymentType: 'payment_type',
      coupon: 'coupon',
      items: 'items',
    },
  },
  insuranceClick: {
    event: 'insurance_click',
    params: { insuranceType: 'insurance_type' },
  },
  cardClick: { event: 'card_click', params: { card: 'card' } },
  insuranceCompany: {
    event: 'insurance_company_details',
    params: { company: 'company' },
  },
  moreInfoHover: {
    event: 'more_info_hover',
    params: { flowName: 'flow_name', stepName: 'step_name', section: 'section' },
  },
  insuranceSort: {
    event: 'insurance_sort',
    params: { sortBy: 'sort_by', flowName: 'flow_name', stepName: 'step_name' },
  },
  purchasedPolicySection: {
    event: 'purchased_policies_sections',
    params: { section: 'section' },
  },
  back: {
    event: 'go_back',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  downloadApp: {
    event: 'app_download_start',
    params: { osMarket: 'os_market' },
  },
  purchaseStart: {
    event: 'purchase_start',
    params: { insuranceType: 'insurance_type' },
  },
  purchase: {
    event: 'purchase',
    params: {
      currency: 'currency',
      value: 'value',
      coupon: 'coupon',
      transactionId: 'transaction_id',
      tax: 'tax',
      items: 'items',
    },
  },
  vehicleDrivers: {
    event: 'vehicle_drivers',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  addDriverStart: {
    event: 'add_driver_start',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  addDriver: {
    event: 'add_driver',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  driverOtherDetails: {
    event: 'driver_other_details',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  deleteDriver: {
    event: 'driver_delete',
    params: { flowName: 'flow_name', stepName: 'step_name' },
  },
  addBasicData: {
    event: 'add_basic_data',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      purpose: 'purpose',
      registrationType: 'registration_type',
      modelYear: 'model_year',
    },
  },
  addInsuranceData: {
    event: 'add_insurance_data',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      purpose: 'purpose',
      insuranceType: 'insurance_type',
      modelYear: 'model_year',
      car: 'car',
      repairMethod: 'repair_method',
    },
  },
  showAll: {
    event: 'show_all_additional_coverage',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      quote: 'quote',
      category: 'category',
      company: 'company',
    },
  },
  errorCaptured: {
    event: 'error_captured',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      errorCode: 'error_code',
      errorMessage: 'error_message',
      type: 'type',
    },
  },
  selectItem: {
    event: 'select_item',
    params: {
      itemListName: 'item_list_name',
      itemListId: 'item_list_id',
      additionalCoverage: 'additional_coverage',
      items: 'items',
    },
  },
  viewItem: {
    event: 'view_item',
    params: {
      currency: 'currency',
      value: 'value',
      items: 'items',
    },
  },
  beginCheckout: {
    event: 'begin_checkout',
    params: {
      currency: 'currency',
      value: 'value',
      items: 'items',
    },
  },
  viewItemList: {
    event: 'view_item_list',
    params: {
      itemListName: 'item_list_name',
      itemListId: 'item_list_id',
      items: 'items',
    },
  },
  intialQuoteListView: {
    event: 'initial_quote_list_view_response',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      category: 'category',
      quote: 'quote',
      count: 'count',
    },
  },
  lateQuoteListView: {
    event: 'late_quote_list_view_response',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      category: 'category',
      quote: 'quote',
      count: 'count',
    },
  },
  otpVerification: {
    event: 'otp_verification',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
    },
  },
  navigationFooter: {
    event: 'navigation_footer',
    params: {
      navTree: 'nav_tree',
      clickText: 'click_text',
    },
  },
  sidebarOpen: {
    event: 'navigation_sidebar_open',
  },
  sidebarDropdown: {
    event: 'navigation_sidebar_dropdown',
    params: {
      clickText: 'click_text',
    },
  },
  sidebarClick: {
    event: 'navigation_sidebar_click',
    params: {
      clickText: 'click_text',
      navTree: 'nav_tree',
    },
  },
  languageChange: {
    event: 'language_change',
    params: {
      language: 'language',
    },
  },
  carStatusSearch: {
    event: 'car_status_search',
    params: {
      vehicleIdentifierType: 'vehicle_identifier_type',
    },
  },
  corporateCommunication: {
    event: 'corporate_communication',
    params: {
      purpose: 'purpose',
    },
  },
  showOnMap: {
    event: 'show_on_map',
    params: {
      purpose: 'purpose',
    },
  },
  faqs: {
    event: 'faqs',
    params: {
      section: 'section',
      question: 'question',
    },
  },
  entitySelect: {
    event: 'entity_select',
    params: {
      entity: 'entity',
    },
  },
  addCoupon: {
    event: 'add_coupon',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      coupon: 'coupon',
    },
  },
  qitafRequestPin: {
    event: 'qitaf_request_pin',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      qitafFlowType: 'qitaf_flow_type',
    },
  },
  qitafConfirmation: {
    event: 'qitaf_confirmation',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      qitafFlowType: 'qitaf_flow_type',
      points: 'points',
      days: 'days',
    },
  },
  printInsuranceCard: {
    event: 'print_insurance_card',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      quote: 'quote',
      company: 'company',
    },
  },
  loginStart: {
    event: 'login_start',
  },
  logout: {
    event: 'logout',
  },
  mabrookPopupViewed: {
    event: 'mabrook_popup_viewed',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      offer: 'offer',
    },
  },
  mabrookPopupCancel: {
    event: 'mabrook_popup_cancel',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      offer: 'offer',
    },
  },
  mabrookPopupUpgrade: {
    event: 'mabrook_popup_upgrade',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      offer: 'offer',
    },
  },
  userAccountCap: {
    event: 'user_account_cap_validation_error',
  },
  switchAccountValidation: {
    event: 'switch_account_validation',
  },
  showComparison: {
    event: 'show_comparison',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
      company: 'company',
    },
  },
  compareStart: {
    event: 'compare_start',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
    },
  },
  skipAddon: {
    event: 'skip_addon',
    params: {
      flowName: 'flow_name',
      stepName: 'step_name',
    },
  },
};

export const getCurrentTabName = (tab: QuoteTypes) => {
  switch (tab) {
    case QuoteTypes.COMP:
      return 'comp';
    case QuoteTypes.OWN_DAMAGE:
      return 'own damage plus';
    default:
      return 'thirdparty/thirdparty plus';
  }
};

export const getRepairMethod = (repairMethod: RepairMethod) => {
  switch (repairMethod) {
    case RepairMethod.AGENCY:
      return 'agency';
    default:
      return 'workshop';
  }
};

export const getPaymentMethod = (paymentMethod: number | string) => {
  switch (Number(paymentMethod)) {
    case 8:
      return 'mastercard';
    case 9:
      return 'visa';
    case 7:
      return 'mada';
    case 29:
      return 'tamara split by 4';
    case 28:
      return 'sadad';
    case 33:
    case 34:
      return 'applepay';
    case 35:
      return 'tamara split by 6';
    default:
      return '-';
  }
};
