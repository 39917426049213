import { TMBIPolicyDetails } from '@models/mbi.model';
import { useTranslation } from 'react-i18next';

type Props = {
  policyData: TMBIPolicyDetails;
  insuranceType: string;
  insuranceTypeAr: string;
  deductible: number;
};
export default function PurchasedPolicyDetails({
  policyData,
  insuranceType,
  insuranceTypeAr,
}: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['mbi', 'purchase']);

  return (
    <section
      data-testid='purchased-policy-details'
      className={`w-full md:w-[56%] text-size-13 border-0 border-b ${language === 'en' ? 'border-l-0 lg:border-l md:border-r' : 'border-r-0 lg:border-r md:border-l'}  md:border-b-0 border-solid border-gray-color-400 p-4`}
    >
      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-issue-date-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-issue-date-label'>
          {t('mbi:Policy_Issue_Date')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-issue-date-value'>
          {policyData?.policyIssueDate ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-effective-date-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-effective-date-label'>
          {t('mbi:policy_effective_date')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-effective-date-value'>
          {policyData?.effectiveDate ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-expiry-date-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-expiry-date-label'>
          {t('mbi:policy_expiry_date')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-expiry-date-value'>
          {policyData?.expiryDate ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-number-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-number-label'>
          {t('mbi:policy_number')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-number-value'>
          {policyData?.policyNumber ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-quote-reference-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-quote-reference-label'>
          {t('mbi:quote_reference')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-quote-reference-value'>
          {policyData?.quoteReference ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-insurance-type-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-insurance-type-label'>
          {t('mbi:insurance_type')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-insurance-type-value'>
          {language === 'en' ? insuranceType : insuranceTypeAr}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-deductible-amount-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-deductible-amount-label'>
          {t('mbi:deductible_amount')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-deductible-amount-value'>
          {policyData?.receiptNumber ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-payment-date-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-payment-date-label'>
          {t('mbi:payment_date')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-payment-date-value'>
          {policyData?.paymentDate ?? '---'}
        </span>
      </div>

      <div
        className='flex items-center justify-between mb-0.5'
        data-testid='policy-receipt-number-container'
      >
        <span className='w-auto md:w-1/2 font-bold' data-testid='policy-receipt-number-label'>
          {t('mbi:receipt_number')}
        </span>
        <span className='w-auto md:w-1/2' data-testid='policy-receipt-number-value'>
          {policyData?.receiptNumber ?? '---'}
        </span>
      </div>
    </section>
  );
}
