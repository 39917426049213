export const setJwtToken = (headers: any) => {
  const token = getToken(headers);
  if (!token) return;

  validateToken(token);
};

function validateToken(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    JSON.parse(jsonPayload);
    return true;
  } catch {
    const ele = window.parent.document.getElementById('Sesssion-expire-popup-trigger');
    ele && ele.click();
    return false;
  }
}
function getToken(headers: any) {
  let token: any;
  const JwtToken =
    JSON.parse(
      localStorage.getItem(
        `oidc.user:${window.settings.VITE_AUTH_AUTHORITY}:${window.settings.VITE_AUTH_CLIENT_ID}`,
      ) as string,
    )?.access_token ?? null;

  const localStorageToken = localStorage.getItem('token');

  if (JwtToken) {
    headers.Authorization = `Bearer ${JwtToken.toString()}`;
    token = headers.Authorization;
  } else if (localStorageToken) {
    headers.token = localStorageToken;
    token = headers.token;
  }
  return token;
}
