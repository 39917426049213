import { LegacyRef } from 'react';

export default function BurgerMenuIcon({
  handleClick,
  humburgerMenuRef,
  isActive,
}: {
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  humburgerMenuRef?: LegacyRef<HTMLDivElement> | undefined;
  isActive?: boolean;
}) {
  return (
    <div
      ref={humburgerMenuRef}
      className={`hamburger ${isActive ? 'is-active' : ''}`}
      id='hamburger-1'
      onClick={handleClick}
    >
      <span className='line'></span>
      <span className='line'></span>
      <span className='line'></span>
    </div>
  );
}
