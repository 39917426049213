import TameeniLoader from '@Components/common/TameeniLoader';
import MBISuccessPage from '@Pages/MBI/MBISuccessPage';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

// Imports Lazy Pages
const HomePage = lazy(() => import('@Pages/HomePage'));
const PurchaseFlowComponent = lazy(() => import('@Pages/PurchaseFlow/PurchaseFlowPage'));
const TPLPage = lazy(() => import('@Pages/car/TPLPage'));
// const COMPPage = lazy(() => import('@Pages/car/COMPPage'));
const COMPPage = lazy(() => import('@Pages/car/ComprehensivePage'));
const MBIPage = lazy(() => import('@Pages/car/MBIPage'));
const SmartPackagePage = lazy(() => import('@Pages/car/SmartPackagePage'));
const MBIHomePage = lazy(() => import('@Pages/MBI/MBIHomePage'));
const MojazPage = lazy(() => import('@Pages/car/MojazPage'));
const MojazHomePage = lazy(() => import('@Pages/Mojaz/MojazHomePage'));
const MojazReport = lazy(() => import('@Components/Mojaz/MojazReport/MojazReport'));
const QitafPage = lazy(() => import('@Pages/car/QitafPage'));
const FAQsPgae = lazy(() => import('@Pages/car/FAQsPgae'));
const ContactUsPage = lazy(() => import('@Pages/ContactUsPage'));
const SamaPoliciesPage = lazy(() => import('@Pages/SamaPoliciesPage'));
const PrivacyPolicyPage = lazy(() => import('@Pages/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(() => import('@Pages/TermsAndConditionsPage'));
const CareersPage = lazy(() => import('@Pages/CareersPage'));
const EntitiesPage = lazy(() => import('@Pages/EntitiesPage'));
const PartnersPage = lazy(() => import('@Pages/Partners/PartnersPage'));
const PId = lazy(() => import('@Pages/Partners/PId'));
const PrintPolicyPage = lazy(() => import('@Pages/car/PrintPolicyPage'));
const NajmStatusPage = lazy(() => import('@Pages/car/NajmStatusPage'));
const DashboardPage = lazy(() => import('@Pages/Individual/ProfilePage/DashboardPage'));
const DashboardContent = lazy(() => import('@Pages/Individual/ProfilePage/DashboardContent'));
const SettingsPage = lazy(() => import('@Pages/Individual/ProfilePage/Settings/SettingsPage'));
const PurchasedPolicies = lazy(() => import('@Pages/Individual/ProfilePage/PurchasedPolicies'));
const QitafTermsAndConditions = lazy(() => import('@Pages/TameeniWallet/QitafTermsAndConditions'));
const ShareQuotes = lazy(() => import('@Pages/Al/SQ'));
const Vp = lazy(() => import('@Pages/Dw/Vp'));
const PaymentTermsPage = lazy(() => import('@Pages/PaymentTerms'));
const VerificationByLinkLanding = lazy(() => import('@Pages/Scheme/VerificationByLinkLanding'));

const CouponTermsAndConditions = lazy(
  () => import('@Pages/TameeniCoupon/CouponTermsAndConditions'),
);

const SettingsContent = lazy(
  () => import('@Pages/Individual/ProfilePage/Settings/SettingsContent'),
);
const ChangePassword = lazy(() => import('@Pages/Individual/ProfilePage/Settings/ChangePassword'));
const ChangeEmail = lazy(() => import('@Pages/Individual/ProfilePage/Settings/ChangeEmail'));
const ChangePhoneNumber = lazy(
  () => import('@Pages/Individual/ProfilePage/Settings/ChangePhoneNumber'),
);
const PreivousQuotes = lazy(() => import('@Pages/Individual/ProfilePage/PreivousQuotes'));
const SadadBill = lazy(() => import('@Pages/Individual/ProfilePage/Sadad/SadadBill'));
const SadadBillDetails = lazy(() => import('@Pages/Individual/ProfilePage/Sadad/SadadBillDetails'));
const DownloadSadadBillDetails = lazy(
  () => import('@Pages/Individual/ProfilePage/Sadad/DownloadSadadBillDetails'),
);
const MojazContent = lazy(() => import('@Pages/Individual/ProfilePage/MojazContent'));
const RenewalPage = lazy(() => import('@Pages/Renewal'));
const RenewalGetLongUrlPage = lazy(() => import('@Pages/RenewalGetLongUrlPage'));
const RenewalCampaignPage = lazy(() => import('@Pages/RenewalCampaignPage'));

const FailedPayments = lazy(() => import('@Pages/FaildPayment/FaildPayment'));
const CheckInvoicePage = lazy(() => import('@Pages/FaildPayment/CheckInvoice'));
const ThankYouPage = lazy(() => import('@Pages/FaildPayment/ThankYou'));

// Post Payment
const SuccessPaymentPage = lazy(() => import('@Pages/PurchaseFlow/SuccessPaymentPage'));
const ProcessingPaymentPage = lazy(() => import('@Pages/PurchaseFlow/ProcessingPaymentPage'));
const NotFoundPage = lazy(() => import('@Pages/NotFoundPage'));
const SharePaymentLink = lazy(() => import('@Pages/PurchaseFlow/SharePaymentLink'));
const LoginPage = lazy(() => import('@Pages/Login'));
const LoginCallBackPage = lazy(() => import('@Pages/LoginCallBack'));

//OD Renewal
const OdRenewal = lazy(() => import('@Pages/OdRenewal/OdRenewal'));
const OdCheckInvoice = lazy(() => import('@Pages/OdRenewal/CheckInvoice'));
const OdThankYou = lazy(() => import('@Pages/OdRenewal/ThankYou'));

//Mobile Team Pages
const TamaraTermsAndConditions = lazy(() => import('@Pages/TamaraTermsAndConditionsPage'));

export default function ProviderRouter() {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation('routes');

  return (
    <Routes>
      <Route
        path={t('routes:routes.home', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.home', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.tpl', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <TPLPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.tpl', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <TPLPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.comp', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <COMPPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.comp', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <COMPPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.mbi-landing-page', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MBIPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.mbi-landing-page', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MBIPage />
          </Suspense>
        }
      />

      <Route
        path={t('routes:routes.smart-package-landing-page', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <SmartPackagePage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.smart-package-landing-page', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <SmartPackagePage />
          </Suspense>
        }
      />

      <Route
        path={t('routes:routes.mbi-success', { lng })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MBISuccessPage />
          </Suspense>
        }
      />
      <Route
        path='/Home/MBIHome'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MBIHomePage />
          </Suspense>
        }
      />

      <Route
        path={t('routes:routes.mojaz-landing-page', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MojazPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.mojaz-landing-page', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MojazPage />
          </Suspense>
        }
      />
      <Route
        path='/Home/Mojaz'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MojazHomePage />
          </Suspense>
        }
      />
      <Route
        path='/Home/MojazSuccess'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <MojazReport />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.qitaf', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <QitafPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.qitaf', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <QitafPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.faqs', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <FAQsPgae />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.faqs', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <FAQsPgae />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.contact-us', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <ContactUsPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.contact-us', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <ContactUsPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.sama-policies', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <SamaPoliciesPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.sama-policies', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <SamaPoliciesPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.privacy-policy', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PrivacyPolicyPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.privacy-policy', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PrivacyPolicyPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.terms-and-conditions', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <TermsAndConditionsPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.terms-and-conditions', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <TermsAndConditionsPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.careers', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <CareersPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.careers', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <CareersPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.entities', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <EntitiesPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.entities', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <EntitiesPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.partners', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PartnersPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.partners', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PartnersPage />
          </Suspense>
        }
      />
      <Route
        path={`${t('routes:routes.partners', { lng: 'ar' })}/:id`}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PId />
          </Suspense>
        }
      />
      <Route
        path={`${t('routes:routes.partners', { lng: 'en' })}/:id`}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PId />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.print-policy', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PrintPolicyPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.print-policy', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PrintPolicyPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.najm-status', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <NajmStatusPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.najm-status', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <NajmStatusPage />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.purchase-flow', { lng: 'ar' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PurchaseFlowComponent />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.purchase-flow', { lng: 'en' })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PurchaseFlowComponent />
          </Suspense>
        }
      />
      <Route
        path='/PaymentTerms'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <PaymentTermsPage />
          </Suspense>
        }
      />
      <Route
        path='/UHome/RW'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <RenewalPage />
          </Suspense>
        }
      />
      <Route
        path='/RW'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <RenewalGetLongUrlPage />
          </Suspense>
        }
      />
      <Route
        path='UHome/Quote'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <RenewalCampaignPage />
          </Suspense>
        }
      />
      <Route
        path='/Individual/ProfilePage'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <DashboardPage />
          </Suspense>
        }
      >
        <Route index element={<Navigate to='Dashboard' />} />
        <Route
          path='Dashboard'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <DashboardContent />
            </Suspense>
          }
        />
        <Route
          path='PurchasedPolicies'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <PurchasedPolicies />
            </Suspense>
          }
        />
        <Route
          path='Mojaz'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <MojazContent />
            </Suspense>
          }
        />
        <Route
          path='PreviousQuoteRequests'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <PreivousQuotes />
            </Suspense>
          }
        />
        <Route
          path='SadadBill'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <SadadBill />
            </Suspense>
          }
        />
        <Route
          path='SadadBillDetails'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <SadadBillDetails />
            </Suspense>
          }
        />
        <Route
          path='DownloadSadadBillDetails'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <DownloadSadadBillDetails />
            </Suspense>
          }
        />
        <Route
          path='Settings'
          element={
            <Suspense fallback={<TameeniLoader />}>
              <SettingsPage />
            </Suspense>
          }
        >
          <Route
            path=''
            element={
              <Suspense fallback={<TameeniLoader />}>
                <SettingsContent />
              </Suspense>
            }
          />
          <Route
            path='ChangePassword'
            element={
              <Suspense fallback={<TameeniLoader />}>
                <ChangePassword />
              </Suspense>
            }
          />
          <Route
            path='ChangeEmail'
            element={
              <Suspense fallback={<TameeniLoader />}>
                <ChangeEmail />
              </Suspense>
            }
          />
          <Route
            path='ChangePhoneNumber'
            element={
              <Suspense fallback={<TameeniLoader />}>
                <ChangePhoneNumber />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path='RetryPayment'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <FailedPayments />
          </Suspense>
        }
      />
      <Route
        path='RetryPayment/CheckInvoice'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <CheckInvoicePage />
          </Suspense>
        }
      />
      <Route
        path='RetryPayment/ThankYou'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <ThankYouPage />
          </Suspense>
        }
      />
      <Route
        path='TameeniWallet/QitafTermsAndConditions'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <QitafTermsAndConditions />
          </Suspense>
        }
      />
      <Route
        path='TameeniCoupon/CouponTermsAndConditions'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <CouponTermsAndConditions />
          </Suspense>
        }
      />
      <Route
        path={t('routes:routes.success-payment', { lng })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <SuccessPaymentPage />
          </Suspense>
        }
      />

      <Route
        path={t('routes:routes.processing-payment', { lng })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <ProcessingPaymentPage />
          </Suspense>
        }
      />

      <Route
        path='*'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <NotFoundPage />
          </Suspense>
        }
      />

      <Route
        path={t('routes:routes.shareLink', { lng })}
        element={
          <Suspense fallback={<TameeniLoader />}>
            <SharePaymentLink />
          </Suspense>
        }
      />
      <Route
        path='/login'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path='/signin-oidc'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <LoginCallBackPage />
          </Suspense>
        }
      />
      <Route
        path='/Al/SQ'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <ShareQuotes />
          </Suspense>
        }
      />
      <Route
        path='/Dw/Vp'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <Vp />
          </Suspense>
        }
      />
      <Route
        path='/Scheme/Verification'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <VerificationByLinkLanding />
          </Suspense>
        }
      />
      <Route
        path='RenewOD'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <OdRenewal />
          </Suspense>
        }
      />
      <Route
        path='RenewOD/CheckInvoice'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <OdCheckInvoice />
          </Suspense>
        }
      />
      <Route
        path='RenewOD/ThankYou'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <OdThankYou />
          </Suspense>
        }
      />
      <Route
        path='Tamara/TermsAndConditions'
        element={
          <Suspense fallback={<TameeniLoader />}>
            <TamaraTermsAndConditions />
          </Suspense>
        }
      />
    </Routes>
  );
}
