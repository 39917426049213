import { TMBIInvoice, TMBIPolicyDetails, TMBIPolicyFeatures } from '@models/mbi.model';
import { useTranslation } from 'react-i18next';
import PolicyFeatures from './PolicyFeatures';
import PurchasedPolicyDetails from './PurchasedPolicyDetails';
import PolicySummary from './PolicySummary';
import { formatCurrency } from 'src/utils/commonUtils';

type Props = {
  policyData: TMBIPolicyDetails;
  invoiceData: TMBIInvoice;
  deductible: number;
  insuranceType: string;
  insuranceTypeAr: string;
  features: TMBIPolicyFeatures[];
};
export default function PolicyDetails({
  policyData,
  invoiceData,
  deductible,
  insuranceType,
  insuranceTypeAr,
  features,
}: Props) {
  const { t } = useTranslation(['mbi']);

  return (
    <section
      className='bg-white shadow-[0_0_15px_0_rgba(0,0,0,0.16)] rounded-[20px] py-5 px-4'
      data-testid='policy-holder-details'
    >
      <h4
        className='border-b border-0 border-solid border-gray-color-400 text-xl pb-3 mb-0'
        data-testid='policy-holder-title'
      >
        {t('mbi:policy_details')}
      </h4>

      <div className='text-[14px] flex flex-col lg:flex-row gap-4' data-testid='policy-data'>
        <PolicyFeatures features={features} />
        <div className='flex w-full flex-col md:flex-row lg:w-[53%] border-0 border-t lg:border-t-0 border-solid border-gray-color-400'>
          <PurchasedPolicyDetails
            policyData={policyData}
            insuranceType={insuranceType}
            insuranceTypeAr={insuranceTypeAr}
            deductible={deductible}
          />
          <PolicySummary invoiceData={invoiceData} />
        </div>
      </div>
      <section className='flex justify-between p-3 border-t border-0 border-solid border-gray-color-400'>
        <h5 className='text-md'>{t('mbi:total_amount')}</h5>
        <div className='flex flex-col'>
          <div className='font-bold flex items-center ms-auto'>
            <span className='text-2xl text-primary-light'>
              {formatCurrency(invoiceData?.total) ?? '---'}
            </span>
            <span className='text-xl whitespace-break-spaces'> {t('mbi:SAR')}</span>
          </div>
          <span className='text-xs'>
            {t('mbi:this_includes_taxes', {
              amount: invoiceData?.brokerCommission ?? '-',
            })}
          </span>
        </div>
      </section>
    </section>
  );
}
