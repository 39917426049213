import Img from '@Components/common/Img';
import { IFooter, TFooterLinks } from '@models/footer.model';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Links({
  activeItems,
  handleToggle,
  sendNavigationFooterEvent,
}: TFooterLinks) {
  const { t } = useTranslation(['footer']);

  return (
    <>
      {(t('footer:links', { returnObjects: true }) as IFooter['links'])?.map((link, i: number) => {
        return (
          <div key={link.id} className='w-full md:w-2/12'>
            <div className={`footer-content ${activeItems.indexOf(link.id) > -1 ? 'active' : ''}`}>
              <div
                className='flex items-center justify-between'
                onClick={() => handleToggle(link.id)}
              >
                <h3 className='footer-title text-white cursor-pointer md:cursor-auto'>
                  {link.title}
                </h3>
                <button className='flex md:hidden border-0 bg-transparent text-white'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    fill='currentColor'
                    className='bi bi-chevron-down'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                    />
                  </svg>
                </button>
              </div>
              <div className='block md:hidden'>
                {activeItems.indexOf(link.id) > -1 && (
                  <ul id={link.id} className='footer-list collapse-footer'>
                    {link.list?.map((item) => {
                      return (
                        <li key={item.id} className='footer-item'>
                          <a
                            className='footer-link text-white'
                            href={item.link}
                            target='_blank'
                            onClick={() => {
                              sendNavigationFooterEvent(
                                item.ga4Name,
                                (
                                  t('footer:links', {
                                    returnObjects: true,
                                    lng: 'en',
                                  }) as IFooter['links']
                                )?.[i].title,
                              );
                            }}
                          >
                            {item.name}
                          </a>
                        </li>
                      );
                    })}
                    {link.id === 'fo3' && (
                      <li className='call-center'>
                        <a
                          target='_blank'
                          href={
                            (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                              ?.link
                          }
                          className='call-center-link'
                          onClick={() => {
                            sendNavigationFooterEvent(t('footer:whatsApp.ga4Name'), t('footer:whatsApp.ga4Name'));
                          }}
                        >
                          <Img
                            src={
                              (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                                ?.icon
                            }
                            alt={
                              (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                                ?.name
                            }
                            title={
                              (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                                ?.name
                            }
                            className='max-w-full'
                          />
                          <span className='hidden lg:flex text-nowrap' dir='ltr'>
                            {
                              (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                                ?.number
                            }
                          </span>
                          <span className='flex lg:hidden'>{t('translation:whatsapp')}</span>
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <div className='hidden md:block'>
                <ul id={link.id} className='footer-list collapse-footer'>
                  {link.list?.map((item) => {
                    return (
                      <li key={item.id} className='footer-item'>
                        <Link
                          className='footer-link text-white hover:text-primary'
                          onClick={() => {
                            sendNavigationFooterEvent(item.ga4Name, (
                              t('footer:links', {
                                returnObjects: true,
                                lng: 'en',
                              }) as IFooter['links']
                            )?.[i].title,);
                          }}
                          to={item.link}
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                  {link.id === 'fo3' && (
                    <li className='call-center'>
                      <a
                        target='_blank'
                        href={
                          (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                            ?.link
                        }
                        onClick={() => {
                          sendNavigationFooterEvent(
                            t('footer:whatsApp.ga4Name'),
                            t('footer:whatsApp.ga4Name', { lng: 'en' }),
                          );
                        }}
                        className='call-center-link'
                      >
                        <Img
                          src={
                            (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                              ?.icon
                          }
                          alt={
                            (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                              ?.name
                          }
                          title={
                            (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                              ?.name
                          }
                        />
                        <span className='hidden lg:flex text-nowrap text-white' dir='ltr'>
                          {
                            (t('footer:whatsApp', { returnObjects: true }) as IFooter['whatsApp'])
                              ?.number
                          }
                        </span>
                        <span className='flex lg:hidden'>{t('translation:whatsapp')}</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
