import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@Redux/store';
import { IPaymentDetails, IPaymentSlice } from '@models/payment.model';

const initialState: IPaymentSlice = {
  appRef: '',
  agreementData: undefined,
  isTamaraSelected: false,
  isReverseQitaf: false,
  isDimmedCoupont: false,
  suggestComp: undefined,
};

export const paymentFlowSlice = createSlice({
  name: 'Payment',
  initialState,
  reducers: {
    setPaymentAppRef: (state, action: PayloadAction<IPaymentSlice['appRef']>) => {
      state.appRef = action.payload;
    },
    setAgreementData: (state, action: PayloadAction<IPaymentSlice['agreementData']>) => {
      state.agreementData = action.payload;
    },
    setIsTamaraSelected: (state, action: PayloadAction<IPaymentSlice['isTamaraSelected']>) => {
      state.isTamaraSelected = action.payload;
    },
    setSummaryDetails: (
      state,
      action: PayloadAction<IPaymentDetails['summaryDetailsResponse']>,
    ) => {
      if (state.agreementData) {
        state.agreementData.summaryDetailsResponse = action.payload;
      }
    },
    setPaymentMethods: (state, action: PayloadAction<IPaymentDetails['paymentMethods']>) => {
      if (state.agreementData) {
        state.agreementData.paymentMethods = action.payload;
      }
    },
    setIsReverseQitaf: (state, action: PayloadAction<IPaymentSlice['isReverseQitaf']>) => {
      state.isReverseQitaf = action.payload;
    },
    setSuggestComp: (state, action: PayloadAction<IPaymentSlice['suggestComp']>) => {
      state.suggestComp = action.payload;
    },
    setDimmedCoupon: (state, action: PayloadAction<IPaymentSlice['isDimmedCoupont']>) => {
      state.isDimmedCoupont = action.payload;
    },
  },
});

export const {
  setPaymentAppRef,
  setAgreementData,
  setIsTamaraSelected,
  setSummaryDetails,
  setPaymentMethods,
  setIsReverseQitaf,
  setSuggestComp,
  setDimmedCoupon
} = paymentFlowSlice.actions;

export const selectPaymentAppRef = (state: RootState) => state.payment.appRef;
export const selectAgreementData = (state: RootState) => state.payment.agreementData;
export const selectIsTamaraSelected = (state: RootState) => state.payment.isTamaraSelected;
export const selectIReverseQitaf = (state: RootState) => state.payment.isReverseQitaf;
export const selectSuggestComp = (state: RootState) => state.payment.suggestComp;
export const dimmedCoupon = (state: RootState) => state.payment.isDimmedCoupont;

export default paymentFlowSlice.reducer;
