export enum FLOWS {
  MOTOR = 'motor',
  MBI = 'mbi',
  MOJAZ = 'mojaz',
}
export enum STEPS {
  BASIC_DATA = 'basic data',
  INSURANCE_DETAILS = 'insurance details',
  QUOTE_LIST = 'quote list',
  SUMMARY_AND_PAYMENT = 'summary and payment',
  SUCCESS_PAGE = 'success page',
  RENEWAL = 'renew insurance',
  PROFILE = 'profile',
  RETRY_PAYMENT = 'retry payment',
  FAILED_PAYMENT_SUCCESS = 'failed payment success',
  MABROOK = 'mabrook popup',
  ADDONS = 'addons',
}
export enum ErrorsTypes {
  FORM = 'form error',
  REQUEST = 'http error',
}

export enum NavigationTrees {
  FOOTER = 'navigation footer',
  SIDEBAR = 'navigation sidebar',
}
