import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Settings } from '@models/settings.model';
import { getEnv } from './utils/envHelper';

const envVariables: Settings = await (
  await fetch(`/settings/${getEnv()}.json`, { cache: 'no-store' })
).json();
window.settings = envVariables;

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['ar', 'en'],
    fallbackLng: 'ar',
    detection: {
      order: ['path', 'queryString', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
      caches: ['cookie'],
      // lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged',
    },
    backend: {
      loadPath: `${window.settings.VITE_RESOURCES_BASE_URL}/locales/{{lng}}/{{ns}}.json?v=${window.settings.VITE_VERSION}`,
    },
  });

export default i18n;
