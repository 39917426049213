import { useCallback, useEffect, useState } from 'react';
import { dataLayerPush } from '@Services/ga4';
import { GA4Events } from '@Services/ga4Events';
import { IFooter, TFooterLinks } from '@models/footer.model';
import Links from './Links';
import Img from '@Components/common/Img';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t, i18n } = useTranslation(['footer']);
  const [activeItems, setActiveItems] = useState<TFooterLinks['activeItems']>([]);
  const [, updateState] = useState<any>(null);
  const forceUpdate = useCallback(() => {
    setTimeout(() => {
      updateState({});
    }),
      1;
  }, [i18n.language]);

  const handleToggle = (key: string) => {
    // Exist in array
    if (activeItems.indexOf(key as string) > -1)
      setActiveItems(activeItems.filter((item) => item !== key));
    else setActiveItems([...activeItems, key]);
  };

  const sendNavigationFooterEvent = (clickText: string,tree: string) => {
    dataLayerPush(GA4Events.navigationFooter.event, [
      {
        key: GA4Events.navigationFooter.params.navTree,
        value: tree,
      },
      {
        key: GA4Events.navigationFooter.params.clickText,
        value: clickText,
      },
    ]);
  };

  useEffect(() => {
    const ele = document.getElementById('switchLng');
    ele?.addEventListener('click', forceUpdate);

    return () => ele?.removeEventListener('click', forceUpdate);
  });

  return (
    <footer className='footer'>
      <div className='container flex flex-col md:flex-row gap-2'>
        <Links
          activeItems={activeItems}
          handleToggle={handleToggle}
          sendNavigationFooterEvent={sendNavigationFooterEvent}
        />
        <div className='w-full md:w-2/12'>
          <div className='footer-vision flex'>
            <Img
              src={(t('footer:vision', { returnObjects: true }) as IFooter['vision'])?.img}
              alt={(t('footer:vision', { returnObjects: true }) as IFooter['vision'])?.title}
              title={(t('footer:vision', { returnObjects: true }) as IFooter['vision'])?.title}
            />
          </div>
        </div>
        <div className='w-full md:w-4/12'>
          <ul className='social-list flex items-center'>
            {(t('footer:socials', { returnObjects: true }) as IFooter['socials'])?.map((social) => {
              return (
                <li key={social.id} className='social-item'>
                  <a
                    target='_blank'
                    href={social.link}
                    className='social-link'
                    onClick={() => {
                      sendNavigationFooterEvent(social.ga4Name, social.ga4Name);
                    }}
                  >
                    <Img
                      src={social.icon}
                      alt={social.name}
                      title={social.name}
                      className='w-[25px]'
                    />
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className='buttons-list flex items-center'>
            {(t('footer:buttons', { returnObjects: true }) as IFooter['buttons'])?.map((button) => {
              return (
                <li key={button.id} className='buttons-item'>
                  <a
                    target='_blank'
                    href={button.link}
                    onClick={() => {
                      dataLayerPush(GA4Events.downloadApp.event, [
                        {
                          key: GA4Events.downloadApp.params.osMarket,
                          value: button.ga4Name,
                        },
                      ]);
                    }}
                  >
                    <Img
                      src={button.img}
                      alt={button.name}
                      title={button.name}
                      className='max-w-full'
                    />
                  </a>
                </li>
              );
            })}
          </ul>
          <div className='text-center md:text-start'>
            <p className='copyright'>
              {
                t('footer:copyright', {
                  newDateYear: new Date().getFullYear(),
                }) as IFooter['copyright']
              }
            </p>
            <p className='copyright'>{t('footer:statement') as IFooter['statement']}</p>
            <p className='copyright'>
              {(t('footer:rasan', { returnObjects: true }) as IFooter['rasan'])?.poweredBy + ' '}
              <Img
                src={(t('footer:rasan', { returnObjects: true }) as IFooter['rasan'])?.logo}
                width={60}
              />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
