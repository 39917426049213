import { Link, useNavigate } from 'react-router-dom';
import Img from '@Components/common/Img';
import { useTranslation } from 'react-i18next';
import BurgerMenuIcon from '@Components/common/BurgerMenuIcon';
import Aside from './Aside';
import useInitLanguage from '@Hooks/useInitLanguage';
import useHeaderSetting from './hooks/useHeaderSetting';
import { useAuth } from 'react-oidc-context';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TameeniLoader from '@Components/common/TameeniLoader';
import useModal from '@Hooks/useModal';
import LoginPopup from '@Components/common/LoginPopup';
import { dataLayerPush } from '@Services/ga4';
import { GA4Events } from '@Services/ga4Events';
import { THomeBanner } from '@models/home.model';
import ApplicationService from '@Services/motor/application';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

export default function Header({
  showBanner,
  setShowBanner,
  isMobile,
}: {
  showBanner: boolean;
  setShowBanner: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
}) {
  const { switchLanguage, isLoading } = useInitLanguage();
  const { t, i18n } = useTranslation(['translation', 'routes']);
  const lang = i18n.language;
  const { show, humburgerMenuRef, handleClick } = useHeaderSetting();
  const auth = useAuth();
  const [showMenu, setShowMenue] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loginLoader, setLoginLoader] = useState<boolean>(false);
  const { showModal, modalTarget, handleShowModal, handleCloseModal } = useModal();
  const [homeBanners, setHomeBanners] = useState<THomeBanner[] | null>(null);

  const windowClick = () => setShowMenue(false);
  useEffect(() => {
    document.addEventListener('click', windowClick);
    return () => document.removeEventListener('click', windowClick);
  });
  useEffect(() => {
    if (!auth.isAuthenticated) {
      sessionStorage.removeItem(
        `oidc.user:${window.settings.VITE_AUTH_AUTHORITY}:${window.settings.VITE_AUTH_CLIENT_ID}`,
      );
    }

    ApplicationService.getHomeBenner()
      .then((res) => {
        setHomeBanners(res);
        if (res.length <= 0) setShowBanner(false);
      })
      .catch(() => {
        setShowBanner(false);
        // DUMMY BANNERS FOR TESTING
        // setHomeBanners([
        //   {
        //     id: 0,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        //   {
        //     id: 1,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        //   {
        //     id: 2,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        //   {
        //     id: 3,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        // ])
      });
  }, []);

  if (loginLoader || isLoading) {
    return <TameeniLoader />;
  }

  const isQuoteListHeader = () => {
    return localStorage.getItem('quoteListHeader') !== null;
  };

  return (
    <>
      <header className='header'>
        {showBanner && homeBanners && homeBanners?.length > 0 && (
          <Swiper
            slidesPerView={1}
            modules={[Autoplay, Navigation]}
            autoHeight={true}
            loop={true}
            speed={500}
            dir={i18n.language === 'en' ? 'ltr' : 'rtl'}
            key={i18n.language}
            autoplay
          >
            {(isMobile ? homeBanners : [homeBanners[0]])?.map((banner) => {
              return (
                <SwiperSlide key={`banner-${banner.id}`}>
                  <div
                    className='bg-white w-full h-auto relative cursor-pointer'
                    onClick={() => window.open(banner.redirectionLink, '_blank')}
                  >
                    <Img
                      src={
                        isMobile
                          ? lang === 'en'
                            ? banner.mobileImageEn
                            : banner.mobileImageAr
                          : lang === 'en'
                            ? banner.imageEn
                            : banner.imageAr
                      }
                      className='w-full h-[calc(100vw*103/375)] md:h-[calc(100vw*100/1440)]'
                    />
                    <span
                      className='absolute top-1 end-2 cursor-pointer text-md md:text-2xl font-bold text-white'
                      onClick={(e) => {
                        e.stopPropagation();
                        sessionStorage.setItem('isHeaderBannerDismissed', 'true');
                        setShowBanner(false);
                      }}
                    >
                      &times;
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {/* <div className='container'> */}
        <nav className='nav flex flex-wrap justify-between items-center -mx-4 py-[0.75rem] px-[1rem]'>
          <div className='flex flex-wrap items-center gap-2 px-4 w-full md:w-auto'>
            {/* burger Menu */}
            <div className={`relative z-[99999] ${show ? 'hidden' : ''}`}>
              <BurgerMenuIcon handleClick={handleClick} humburgerMenuRef={humburgerMenuRef} />
            </div>
            {/* Logo */}
            <Link to={t('routes:routes.home')} className='inline-flex justify-center flex-1'>
              <Img
                src='/Resources/images/header/header-logo.svg'
                title={lang === 'en' ? 'Tameeni Compare and Buy Unsurance' : 'تأميني'}
                className='w-[100px] md:w-[140px]'
              />
            </Link>
          </div>
          <div className='hidden md:flex flex-wrap items-center gap-2 px-4'>
            <div className='relative'>
              <button
                type='button'
                id='login-btn'
                className={`basic-btn ${
                  auth.isAuthenticated || isQuoteListHeader() ? 'border-0 justify-end' : ''
                }`}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (auth.isAuthenticated || isQuoteListHeader()) {
                    setShowMenue((prev) => !prev);
                  } else {
                    const element = document.getElementById('Login-popup-trigger');
                    element && element.click();
                  }
                }}
              >
                {isQuoteListHeader() && !auth.isAuthenticated
                  ? t('translation:quotations_list')
                  : !auth.isAuthenticated
                    ? t('translation:login')
                    : t('translation:myAccount')}
              </button>
              {showMenu && (auth.isAuthenticated || isQuoteListHeader()) && (
                <ul className='absolute top-full end-2 bg-white rounded-lg border border-gray-200 border-solid shadow-md min-w-[140px]'>
                  {(auth.isAuthenticated || (!auth.isAuthenticated && isQuoteListHeader())) && (
                    <li className='py-2 px-4 border-b border-0 border-gray-200 border-solid '>
                      <Link
                        className='text-[14px] font-semibold text-text-color hover:text-secondary'
                        to='/Individual/ProfilePage'
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
                      >
                        {t('translation:myAccount')}
                      </Link>
                    </li>
                  )}
                  <li className='py-2 px-4'>
                    <Link
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.stopPropagation();
                        e.preventDefault();

                        const eleLogin = document.getElementById('Login-popup-trigger');
                        if (auth.isAuthenticated) {
                          setLoginLoader(true);
                          dataLayerPush(GA4Events.logout.event, []);
                          auth.signoutSilent().then(() => {
                            navigate('/', { replace: true });
                            localStorage.clear();
                            setLoginLoader(false);
                          });
                        } else {
                          dataLayerPush(GA4Events.loginStart.event, []);
                          eleLogin && eleLogin.click();
                        }
                      }}
                      className='text-[14px] font-semibold text-text-color hover:text-secondary'
                      to='/'
                    >
                      {auth.isAuthenticated ? t('translation:logout') : t('translation:login')}
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <Link
              to='/'
              id='switchLng'
              onClick={switchLanguage}
              className='font-bold text-text-color header_lang'
            >
              {t('translation:lang')}
            </Link>
          </div>
        </nav>
        {/* </div> */}
        <Aside showAside={show} closeAside={handleClick} switchLanguage={switchLanguage} />
        <a
          id={'Login-popup-trigger'}
          className='hidden'
          href={'#Login-popup'}
          data-target-modal='#Login-popup'
          data-testid={'login-popup'}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleShowModal(e)}
        />
        {showModal && modalTarget === '#Login-popup' && (
          <LoginPopup handleClose={handleCloseModal} />
        )}
      </header>
    </>
  );
}
