import OpenGraphTags from '@Components/common/OpenGraphTags';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import { useEffect, useState } from 'react';

export default function Layout({ children }: { children: React.ReactNode }) {
  const [showBanner, setShowBanner] = useState<boolean>(
    !sessionStorage.getItem('isHeaderBannerDismissed') ?? true,
  );
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 767.99) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);
  return (
    <>
      <OpenGraphTags />
      <Header showBanner={showBanner} setShowBanner={setShowBanner} isMobile={isMobile} />
      <main className={showBanner ? (isMobile ? 'pt-[26%]' : 'pt-[7%]') : ''}>{children}</main>
      {!navigator.userAgent.includes('HybridApp') && <Footer />}
    </>
  );
}
