import { useEffect, useState } from 'react';
import MetaTags from './MetaTags';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
type TAG = {
  paths: string[];
  titleEn: string;
  titleAr: string;
  descriptionEn: string;
  descriptionAr: string;
};
const OpenGraphTags = () => {
  const { pathname } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();
  const [tagUsed, setTagUsed] = useState<TAG>();
  const TAGS: TAG[] = [
    {
      paths: ['/Al/SQ', '/UHome/PurchaseFlow'],
      titleEn: 'Tameeni- Quote List',
      titleAr: 'تأميني - قائمة الأسعار',
      descriptionEn: '',
      descriptionAr:
        'قارن عروض و اسعار تأمين السيارات خلال دقائق و احصل علي وثيقتك بشكل فوري. جمعنا شركات التأمين في مكان واحد عشان نسهلها عليك',
    },
    {
      paths: ['/', '/en'],
      titleEn: 'Tameeni - One-shop-stop for Insurance',
      titleAr: 'تأميني المنصة الأولى لمقارنة أسعار تأمين المركبات',
      descriptionEn:
        'Compare car insurance offers and prices within minutes of more than 20 approved insurance companies in the Kingdom and get your policy instantly',
      descriptionAr:
        'قارن عروض وأسعار تأمين السيارات  خلال دقائق واحصل على وثيقتك بشكل فوري. جمعنا شركات التأمين في مكان واحد عشان نسهلها عليك.',
    },
    {
      paths: ['/car/تأمين-ضد-الغير', '/car/en/third-party-insurance'],
      titleEn: 'Tameeni - Third Party Insurance',
      titleAr: 'تأميني - تأمين ضد الغير',
      descriptionEn:
        'A simple Accident can cost you a lot, to avoid repair costs, buy Third Party Insurance',
      descriptionAr:
        'الحـادث البـسـيـط 🙌🏻 بيكلفك الكثير وعشان تتفادى تكاليف الإصلاح اشتر تأمين ضد الغير',
    },
    {
      paths: ['/car/التأمين-الشامل', '/car/en/Comprehensive-Insurance'],
      titleEn: 'Tameeni - Comprehensive Insurance',
      titleAr: 'تأميني - تأمين شامل',
      descriptionEn:
        'Because comprehensive insurance is the best insurance option ☝🏻 We have it in installments... Install your comprehensive insurance now',
      descriptionAr:
        'لأن التأمين الشامل الخــيـار التأمـيـني الأفـضــل ☝🏻عندنا بالأقساط  .. قسّط تأمينك الشامل الآن',
    },
    {
      paths: ['/car/en/vehicle-history-check-mojaz', '/car/معلومات-المركبة-من-موجز'],
      titleEn: 'Tameeni - Mojaz',
      titleAr: 'تأميني - موجز',
      descriptionEn:
        'Know the used vehicle detailed reporting from Mojaz report 🚗 and insure it after you buy it',
      descriptionAr:
        'اعرف بيانات السيارة المستعملة وكل تفاصيلها من تقرير موجز 🚗 وأمّن عليها بعد ما تشتريها',
    },
    {
      paths: ['/car/تأمين-الأعطال-الميكانيكية', '/car/en/mechanical-breakdown-insurance'],
      titleEn: 'Tameeni - Mechanical Breakdown Insurance',
      titleAr: 'تأميني - تأمين الأعطال الميكانيكيه',
      descriptionEn:
        'Insurance coverage for your vehicle that includes labor and the cost of repairing mechanical and electrical faults.',
      descriptionAr:
        'جـمـيع المحــركـات📍لا تشيل همها وأمّن عليها بوجود تأمين الأعطال الميكانيكية والكهربائية',
    },
    {
      paths: ['/car/الباقة-الذكية', '/car/en/Smart-Package'],
      titleEn: 'Tameeni - Smart Package',
      titleAr: 'تأميني - الباقة الذكية',
      descriptionEn:
        'Enjoy hassle-free Insurance purchasing without the burden of full payment upfront and split your payment into 4 installments',
      descriptionAr: 'عشان ما تمشي بدون تأمين أضفنا لك خيار الأقساط الميسرة على دفعات',
    },
    {
      paths: ['/RetryPayment'],
      titleEn: 'Tameeni - Retry Payment',
      titleAr: 'تأميني - اعادة محاولة الدفع',
      descriptionEn:
        'Your customized insurance offer is still up for grabs, you are one step away from completing your payment',
      descriptionAr: 'عرض التأمين الخاص فيك ينتظرك , اضغط على الرابط لتتمكن من الدفع بطريقة آمنة',
    },
  ];
  useEffect(() => {
    TAGS.some((tag) => {
      const foundMatchingPathIndex = tag.paths.findIndex(
        (path) =>
          // pathname.includes(path)
          path.toLowerCase() === decodeURI(pathname).toLowerCase(),
      );
      if (foundMatchingPathIndex > -1) {
        setTagUsed(tag);
        return true;
      } else {
        setTagUsed(undefined);
        return false;
      }
    });
  }, [pathname]);

  return tagUsed ? (
    <MetaTags
      title={language === 'en' ? tagUsed.titleEn : tagUsed.titleAr}
      description={language === 'en' ? tagUsed.descriptionEn : tagUsed.descriptionAr}
    />
  ) : undefined;
};

export default OpenGraphTags;
