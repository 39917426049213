import { TMBIvehicle } from '@models/mbi.model';
import { useTranslation } from 'react-i18next';

type Props = {
  vehicleData: TMBIvehicle | undefined;
};

export default function VehicleDetails({ vehicleData }: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['mbi', 'translation']);

  return (
    <section
      className='bg-white shadow-[0_0_15px_0_rgba(0,0,0,0.16)] rounded-[20px] py-5 px-4'
      data-testid='vehicle-details'
    >
      <h4
        className='border-b border-0 border-solid border-gray-color-400 text-xl pb-3'
        data-testid='vehicle-title'
      >
        {t('mbi:vehicle_details')}
      </h4>
      <div className='p-3 text-[14px]' data-testid='vehicle-data'>
        <div className='flex items-center' data-testid='vehicle-sequence-number-container'>
          <span className='w-1/2 font-bold' data-testid='vehicle-sequence-number-label'>
            {t('translation:sequence_number')}
          </span>
          <span className='w-1/2' data-testid='vehicle-sequence-number-value'>
            {vehicleData?.sequenceNumber ?? '---'}
          </span>
        </div>
        <div className='flex items-center' data-testid='vehicle-make-model-container'>
          <span className='w-1/2 font-bold' data-testid='vehicle-make-model-label'>
            {t('mbi:vehicle_make_model')}
          </span>
          <span className='w-1/2' data-testid='vehicle-make-model-value'>
            {`${vehicleData?.make ?? '-'} / ${vehicleData?.model ?? '-'}`}
          </span>
        </div>

        <div className='flex items-center' data-testid='vehicle-plate-number-container'>
          <span className='w-1/2 font-bold' data-testid='vehicle-plate-number-label'>
            {t('translation:PlateNumber')}
          </span>
          <span className='w-1/2' data-testid='vehicle-plate-number-value'>
            {language === 'en'
              ? vehicleData?.plateNumberArabic ?? '---'
              : vehicleData?.plateNumberEnglish ?? '---'}
          </span>
        </div>

        <div className='flex items-center' data-testid='vehicle-manufacturing-year-container'>
          <span className='w-1/2 font-bold' data-testid='vehicle-manufacturing-yea-label'>
            {t('translation:year_of_manufacturing')}
          </span>
          <span className='w-1/2' data-testid='vehicle-manufacturing-yea-value'>
            {vehicleData?.manufactureYear ?? '---'}
          </span>
        </div>

        <div className='flex items-center' data-testid='vehicle-color-container'>
          <span className='w-1/2 font-bold' data-testid='vehicle-color-label'>
            {t('translation:Color')}
          </span>
          <span className='w-1/2' data-testid='vehicle-color-value'>
            {vehicleData?.majorColor ?? '---'}
          </span>
        </div>
      </div>
    </section>
  );
}
