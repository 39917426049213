export enum InspectionTypesModel {
  GENERAL_PHOTO = 2,
  LIVE_PHOTO = 3,
  PHYSICAL_INSPECTION = 4,
}
export enum QuoteTypes {
  TPL = 1,
  COMP = 2,
  TPL_PLUS = 3,
  PREV = 4,
  OWN_DAMAGE = 5,
}

export enum RepairMethod {
  AGENCY = 1,
  WORKSHOP = 2,
}
export enum SuggestionCachedType {
  Cheaper,
  SamePrice,
  Higher,
}

export enum BNPLOptions {
  TAMARA_SPLIT_BY_4 = 4,
  TAMARA_SPLIT_BY_6 = 6,
}
