import { TMBIInvoice, TMBIPriceItem } from '@models/mbi.model';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'src/utils/commonUtils';

type Props = {
  invoiceData: TMBIInvoice;
};
export default function PolicySummary({ invoiceData }: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['mbi']);

  return (
    <section
      data-testid='policy-summary-details'
      className='w-full md:w-[44%] mt-4 px-4 text-[14px] font-bold'
    >
      <div className='flex items-center justify-between' data-testid='policy-price-container'>
        <span className='text-size-13' data-testid='policy-price-label'>
          {t('mbi:policy_price_amount')}
        </span>
        <span data-testid='policy-price-value'>
          {formatCurrency(invoiceData?.policyPriceAmount) ?? '---'} {t('mbi:SAR')}
        </span>
      </div>

      <small className='flex items-center' data-testid='policy-includes-the-following-label'>
        {t('mbi:includes_the_following')}
      </small>

      {invoiceData && invoiceData?.discounts?.length > 0 && (
        <div
          className='flex flex-col justify-between font-normal'
          data-testid='discounts-container'
        >
          {invoiceData?.discounts?.map((discount: TMBIPriceItem, index: number) => (
            <div
              key={`discount-${index}`}
              className='flex justify-between'
              data-testid={`${discount.nameEnglish}-item`}
            >
              <span data-testid={`${discount.nameEnglish}-Label`}>
                {language === 'en' ? discount?.nameEnglish : discount?.nameEnglish}
              </span>
              <span data-testid={`${discount.nameEnglish}-${index}`}>
                ({formatCurrency(discount?.amount) ?? '---'} {t('mbi:SAR')})
              </span>
            </div>
          ))}
        </div>
      )}

      {invoiceData && invoiceData?.breakdowns?.length > 0 && (
        <div
          className='flex flex-col justify-between font-normal'
          data-testid='breakDowns-container'
        >
          {invoiceData?.breakdowns?.map((breakDown: TMBIPriceItem, index: number) => (
            <div
              key={`breakDown-${index}`}
              className='flex justify-between w-full'
              data-testid={`${breakDown.nameEnglish}-item`}
            >
              <span data-testid={`${breakDown.nameEnglish}-Label`}>
                {language === 'en' ? breakDown?.nameEnglish : breakDown?.name}
              </span>
              <span data-testid={`${breakDown.nameEnglish}-${index}`}>
                ({formatCurrency(breakDown?.amount) ?? '---'} {t('mbi:SAR')})
              </span>
            </div>
          ))}
        </div>
      )}

      <div
        className='flex items-center justify-between -ms-4 pt-4 mt-4 pb-0 border-0 border-t border-solid border-gray-color-400'
        data-testid='policy-sub-total-container'
      >
        <span className='ms-4' data-testid='policy-sub-total-label'>
          {t('mbi:sub_total')}
        </span>
        <span data-testid='policy-sub-total-value'>
          {formatCurrency(invoiceData?.policyPriceAmount) ?? '---'} {t('mbi:SAR')}
        </span>
      </div>

      <div className='flex items-center justify-between pb-4' data-testid='policy-vat-container'>
        <span data-testid='policy-vat-label'>{`${t('mbi:VAT')} (${invoiceData?.vatPercentage?.toFixed(2)}%)`}</span>
        <span data-testid='policy-vat-value'>
          {formatCurrency(invoiceData?.vatAmount) ?? '---'} {t('mbi:SAR')}
        </span>
      </div>
    </section>
  );
}
