import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@Redux/store';
import { IOffers } from '@models/offers.model';

const initialState: IOffers = {
  offerMode: 0,
  offerDetails: null,
  offerTabs: [],
  activeTab: '',
  config: {},
  showModal: false,
};

export const offersSlice = createSlice({
  name: 'Offers',
  initialState,
  reducers: {
    setSelectedOffer: (state, action: PayloadAction<IOffers['offerDetails']>) => {
      state.offerDetails = action.payload;
    },
    setOfferMode: (state, action: PayloadAction<IOffers['offerMode']>) => {
      state.offerMode = action.payload;
    },
    setOfferTabs: (state, action: PayloadAction<IOffers['offerTabs']>) => {
      state.offerTabs = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<IOffers['activeTab']>) => {
      state.activeTab = action.payload;
    },
    setConfig: (state, action: PayloadAction<IOffers['config']>) => {
      state.config = action.payload;
    },
    setShowModal: (state, action: PayloadAction<IOffers['showModal']>) => {
      state.showModal = action.payload;
    },
  },
});

export const {
  setSelectedOffer,
  setOfferMode,
  setOfferTabs,
  setActiveTab,
  setConfig,
  setShowModal,
} = offersSlice.actions;

export const selectOfferDetails = (state: RootState) => state.offers.offerDetails;

export const selectOfferMode = (state: RootState) => state.offers.offerMode;

export const selectOfferTabs = (state: RootState) => state.offers.offerTabs;

export const selectActiveTab = (state: RootState) => state.offers.activeTab;

export const selectConfig = (state: RootState) => state.offers.config;

export const rootShowModal = (state: RootState) => state.offers.showModal;

export default offersSlice.reducer;
